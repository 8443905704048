import HomeLayout from '../layouts/homeLayout';
import HomeLayoutMain from '../layouts/homeLayoutMain';
import AdminLayout from '../layouts/adminLayout';
import LoginLayout from '../layouts/adminLoginLayout';

import HomePage from '../pages/home';
import ProducPage from '../pages/product';
import GalleryPage from '../pages/gallery';
import NotFoundPage from '../pages/notFound';
import AdminLoginPage from '../pages/adminLogin';
import AdminProfilePage from '../pages/adminProfile';
import ProductDetailPage from '../pages/productDetail';
import ForgotPasswordPage from '../pages/forgotPassword';
import AdminDashboardPage from '../pages/adminDashboard';
import ResetPasswordPage from '../pages/adminResetPassword';
import AdminUserManagementPage from '../pages/adminUserManagement';
import AdminProductManagementPage from '../pages/adminProductManagement';
import AdminGalleryManagementPage from '../pages/adminGalleryManagement';
import AdminContactManagementPage from '../pages/adminContactManagement';
import AdminCategoryManagementPage from '../pages/adminCategoryManagement';
import AdminUserDetailManagementPage from '../pages/adminUserDeatilManagement';
import AdminCategoryDetailManagement from '../pages/adminCategoryDetailManagement';
import AdminProductDetailManagementPage from '../pages/adminProductDetailManagement';

const components = {
    home: {
        key: "home",
        isFirstPage: true,
        activeKey: "home",
        url: "/",
        page: HomePage,
        layout: HomeLayoutMain,
    },
    product: {
        key: "product",
        activeKey: "product",
        url: "/product",
        page: ProducPage,
        layout: HomeLayout,
    },
    productDetail: {
        key: "product",
        activeKey: "product",
        url: "/product/:categoryKey",
        page: ProductDetailPage,
        layout: HomeLayout,
    },
    gallery: {
        key: "gallery",
        activeKey: "gallery",
        url: "/gallery",
        page: GalleryPage,
        layout: HomeLayout,
    },
    adminLogin: {
        key: "admin-login",
        activeKey: "admin-login",
        url: "/admin-login",
        page: AdminLoginPage,
        layout: LoginLayout,
    },
    adminDashboard: {
        key: "admin-dashboard",
        activeKey: "admin-dashboard",
        url: "/admin-dashboard",
        page: AdminDashboardPage,
        layout: AdminLayout,
    },
    adminUserManagement: {
        key: "admin-users",
        activeKey: "admin-users",
        url: "/admin-user",
        page: AdminUserManagementPage,
        layout: AdminLayout,
    },
    adminUserDetailManagement: {
        key: "admin-user-detail",
        activeKey: "admin-users",
        url: "/admin-user/:userID",
        page: AdminUserDetailManagementPage,
        layout: AdminLayout,
    },
    adminProductManagement: {
        key: "admin-product",
        activeKey: "admin-product",
        url: "/admin-product",
        page: AdminProductManagementPage,
        layout: AdminLayout,
    },
    adminProductDetailManagement: {
        key: "admin-product-detail",
        activeKey: "admin-product",
        url: "/admin-product/:productID",
        page: AdminProductDetailManagementPage,
        layout: AdminLayout,
    },
    adminCategoryManagement: {
        key: "admin-category",
        activeKey: "admin-product",
        url: "/admin-category",
        page: AdminCategoryManagementPage,
        layout: AdminLayout,
    },
    adminCategoryDetailManagement: {
        key: "admin-category-detail",
        activeKey: "admin-product",
        url: "/admin-category/:categoryKey",
        page: AdminCategoryDetailManagement,
        layout: AdminLayout,
    },
    adminGalleryManagement: {
        key: "admin-gallery",
        activeKey: "admin-gallery",
        url: "/admin-gallery",
        page: AdminGalleryManagementPage,
        layout: AdminLayout,
    },
    adminContactManagement: {
        key: "admin-contact",
        activeKey: "admin-contact",
        url: "/admin-contact",
        page: AdminContactManagementPage,
        layout: AdminLayout,
    },
    adminProfileManagement: {
        key: "admin-profile",
        activeKey: "admin-profile",
        url: "/admin-profile",
        page: AdminProfilePage,
        layout: AdminLayout,
    },
    forgotPassword: {
        key: "forgot-password",
        activeKey: "forgot-password",
        url: "/forgot-password",
        page: ForgotPasswordPage,
        layout: LoginLayout,
    },
    resetPassword: {
        key: "reset-password",
        activeKey: "reset-password",
        url: "/reset-password/:resetToken",
        page: ResetPasswordPage,
        layout: LoginLayout,
    },
    notFound: {
        key: "pageNotFound",
        activeKey: "pageNotFound",
        url: "*",
        page: NotFoundPage,
        layout: null
    }
};

const {
    home,
    product,
    productDetail,
    gallery,
    adminLogin,
    adminDashboard,
    adminUserManagement,
    adminUserDetailManagement,
    adminProductManagement,
    adminProductDetailManagement,
    adminCategoryManagement,
    adminCategoryDetailManagement,
    adminGalleryManagement,
    adminContactManagement,
    adminProfileManagement,
    forgotPassword,
    resetPassword,
    notFound,
} = components;

const router = [home, product, productDetail, gallery, adminLogin, adminDashboard, adminUserManagement, adminUserDetailManagement, adminProductManagement, adminProductDetailManagement, adminCategoryManagement, adminCategoryDetailManagement, adminGalleryManagement, adminContactManagement, adminProfileManagement, forgotPassword, resetPassword, notFound];
export default router;

let defaultUrl = '/';
for(const key in components) {
    const pageObj = components[key];
    if(pageObj.hasOwnProperty('isFirstPage') && pageObj['isFirstPage']) {
        defaultUrl = pageObj['url'];
        break;
    }
}
export const firstPageUrl = defaultUrl;