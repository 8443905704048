import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const SearchTextField = ({searchVal = '', setSearchVal = () => {}, contextObj}) => {
    const [searchValue, setSearchValue] = useState(searchVal || '');

    useEffect(() => {
        if(searchVal !== searchValue){
            setSearchValue(searchVal);
        }
    }, [searchVal, searchValue])

    const handleOnSearch = (val) => {
        setSearchValue(val);
        setSearchVal(val);
    }

    return (
        <TextField id="search" label={contextObj('common', 'search')} variant="outlined" size="small" autoComplete="off" fullWidth type={"text"} value={searchValue} onChange={(e)=>handleOnSearch(e.target.value)} className="max-w-sm"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="searching" onClick={()=>{}} edge="end">
                            <SearchIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )

}

export default SearchTextField;