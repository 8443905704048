class FileObject {

    static async convertToBase64(file){
        if(!file) return;
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => { resolve(fileReader.result) }
            fileReader.onerror = (error) => { reject(error) }
        })
    }

    static async convertToBase64WithDataObj(file){
        if(!file) return;
        return new Promise((resolve, reject) => {
            if(!file) return resolve(null)
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => { resolve({base64: fileReader.result, fileName: file.name, fileType: file.type }) }
            fileReader.onerror = (error) => { reject(null) }
        })
    }

}

export default FileObject;