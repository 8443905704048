import React from 'react';
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';


const ModalProductDetail = ({open = false, handleClose = () => {}, title = '', description = '', images = [], contextObj}) => {
    const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={handleClose} aria-labelledby="modal-product-detail-title" aria-describedby="modal-product-detail-detail">
            <DialogTitle id="modal-product-detail-title">
                {title}
            </DialogTitle>
            <DialogContent id="modal-product-detail-detail">
                {images && (images?.length > 1) ? 
                    <Swiper className='cursor-grab' loop={true} autoHeight={true} centeredSlides={true} navigation={true} pagination={{ clickable: true, renderBullet: function (index, className) { return '<span class="' + className + '"></span>'; } }} modules={[Navigation, Pagination]}
                        style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                        }}
                    >
                        {images?.map((obj, index) => {
                            return(
                                <SwiperSlide key={`vandaseeds-product-image-${index}`}>
                                    <img alt={`vandaseeds-product-pic-${index}`} className="w-full" src={obj} loading="lazy"/>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                :
                    <img alt={`vandaseeds-product-pic`} className="w-full" src={images[0]} loading="lazy"/>
                }
                <DialogContentText className='pt-4'>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions className='px-6 py-4'>
                <Button variant='contained' color='inherit' onClick={handleClose}>
                    {contextObj('common', 'close')}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ModalProductDetail;