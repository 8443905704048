const en = {
    common: {
        send: 'Send',
        ok: "OK",
        admin: 'admin',
        login: 'Login',
        email: 'Email',
        password: 'Password',
        cancel: 'Cancel',
        delete: 'Delete',
        search: 'Search',
        tel: 'Tel',
        fax: 'Fax',
        submit: 'Submit',
        mobile: 'Mobile',
        address: '176 Moo5, Namprare, Hangdong, Chiang Mai, 50230 Thailand.',
        somethingWrong: 'Something went wrong, Please try again.',
        areYouSure: 'Are you sure?',
        areYouSureDelete: "Are you sure you want yo delete",
        dropFile: "Drop some files to attach or browse",
        noResultsFound: "No results found",
        close: "close"
    },
    menu: {
        home: 'Home',
        product: 'Product',
        gallery: 'Gallery',
    },
    enhancedTable: {
        resultNotFound: "Result not found",
        rowsPerPage: "Rows per page:"
    },
    home: {
        secQuote: "Vandaseeds Company is located at 176 Moo5, Namprare, Hangdong, Chiang Mai, Thailand. The company was established by Mr. Prathueng Chiangraeng and his wife since 11st December 2001. We focus on breeding new varieties and producing and selling F.1 Hybrid seeds. With the collaboration of experts in research, the company has collected knowledge and experiences in vegetable seed varieties development and sold both locally and abroad for over 10 years. We maintain our standards and seed quality to make sure that our customers get the best products and guarantee seeds purity up to 96-99%. With that, it makes us become one of the leaders in vegetable seed breeding in Thailand and produce strong seeds to serve the market demands.",
        catalogOuote: "A new choice for farmers who are satisfied with the high-quality seeds giving high yielding, with high immunity, low cost of production, being environmentally friendly and enhancing quality of life of farmers, as well as consumers.",
        ourCatalog: "Our catalog",
        contactUs: "Contact us",
        downloadNow: "Download Now",
        name: "Name",
        email: "Email",
        mobile: "Mobile",
        invalidMobile: "Enter a valid phone number",
        message: "Message",
        nameInvalid: "Enter a name",
        emailInvalid: "Enter an email",
        phoneNumberInvalid: "Enter a phone number",
        siteLink: 'Site link',
        followUs: 'Follow us',
        followUsQuote: 'Follow us on our social networks',
        saleTeam: 'sale team'
    },
    adminLogin: {
        forgotPassword: 'Forgot password'
    },
    adminMenu: {
        dashboard: "Dashboard",
        products: "Products",
        addProduct: "Add product",
        category: "Category",
        addCategory: "Add category",
        gallery: "Gallery",
        users: "Users",
        contacts: "Contacts",
        profile: "Profile",
        logout: "Logout",
        addUser: "Add user"
    },
    adminCommon: {
        title: 'Title',
        invalidTitle: "Enter a title",
        lastUpdate: 'Last Update',
        description: "Description",
        pleaseSelect: "Please select"
    },
    adminCategory: {
        popular: "Popular",
        thumbnail: "Thumbnail",
        createCategory: "Create category"
    },
    adminProduct: {
        createProduct: "Create product",
        categoryTitle: "Category title",
        invalidCategory: "Choose a category"
    },
    adminUser: {
        createUser: "Create user",
        emailInvalid: "Enter an email",
        currentPassword: "Current password",
        newPassword: "New password",
        displayName: "Display name",
        lastLogin: "Last login",
        invalidDisplayName: "Enter a display name",
        confirmPassword: "Confirm password",
        passwordInvalid: "Password must contain at least 8 characters with 1 Uppercase letter, 1 Lowercase Letter, 1 Number & 1 Symbol.",
        passwordInvalid2: "Enter a password",
        passwordInvalid3: "The passwords didn’t match",
        confirmPasswordInvalid: "Enter a confirm password",
        changePassword: "Change password"
    },
    adminContact: {
        contactName: "Contact name",
        contactTel: "Tel",
        contactInfo: "Contact info",
        contactAt: "Contact at"
    },
    forgotAndResetPassword: {
        forgotPassword: "Forgot password",
        resetPassword: "Reset password",
    }
}

export default en;