import React from 'react';
import { Divider } from '@mui/material';

import useStyles from '../assets/styles';
import HomeLoading from '../components/homeLoading';
import { useGetGalleryListQuery } from '../reducers/gallery';
import ImageWithOnErrorFrontend from '../components/imageWithOnErrorFrontend';

const API_URL = process.env.REACT_APP_API_URL;

const GalleryPage = ({contextObj}) => {
    const classes = useStyles();
    const { data: galleryList, isLoading: galleryListLoading } = useGetGalleryListQuery();

    return (
        galleryListLoading ? <HomeLoading /> :
        galleryList ?
        <div style={{flex: 1}}>
            <div className="container mx-auto py-4">
                <div className='p-4'>
                    <span className={`${classes.mainTextColor} font-bold text-3xl`}>{contextObj('menu', 'gallery')}</span>
                    <Divider className='pt-1' />
                </div>
                <div className="columns-sm px-4">
                    {galleryList?.map((obj, index)=>{
                        const genMargin = !index ? 'mb-4' : 'my-4';
                        const checkPosition = ((index%4)===0) ? 'aspect-video' : 'aspect-square';
                        return(
                            <div key={`gallery-images-${index}`} className={`${genMargin} ${checkPosition} relative cursor-pointer transform scale-100 hover:scale-95`}>
                                <ImageWithOnErrorFrontend src={API_URL+'images/'+obj['file_name']} alt={`gallery-images-${index}`} className={`${checkPosition} w-full object-cover rounded-xl`} onLoad={() => { URL.revokeObjectURL(API_URL+'images/'+obj['file_name']) }} />
                                <div className="absolute inset-0 ring-1 ring-inset ring-black/10 rounded-xl"></div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
        : null
    );
}

export default GalleryPage;