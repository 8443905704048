var IsProductNeedRefetch = false;

const DataSingleton = {
    get IsProductNeedRefetch() {
        return IsProductNeedRefetch;
    },
    set IsProductNeedRefetch(newIsProductNeedRefetch) {
        IsProductNeedRefetch = newIsProductNeedRefetch;
    }
}

export default DataSingleton;