import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, TextField } from '@mui/material';

import Validator from '../utils/Validator';
import { openSnackbar } from '../reducers/notify';
import { useCreateContactMutation } from '../reducers/contact';

const ContactForm = ({contextObj}) => {
    const dispatch = useDispatch();
    const [ createContact ] = useCreateContactMutation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [inValidPhoneNumber, setInValidPhoneNumber] = useState(false);

    const submitContact = (e) => {
        e.preventDefault();
        setIsSubmit(true);
        if(!name || !email || !phoneNumber){ 
            return;
        }else if(phoneNumber && !Validator.validateData(phoneNumber, 'phoneNumber').isValid){
            setInValidPhoneNumber(true);
            return;
        }

        createContact({name, email, message, phoneNumber: phoneNumber?.toString()}).then((res)=>{
            if(res.data?.statusCode === 201){
                setName("");
                setEmail("");
                setMessage("");
                setIsSubmit(false);
                setPhoneNumber("");
                setInValidPhoneNumber(false);
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    return (
        <form className='pt-6' onSubmit={submitContact}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField id="contact-name" label={contextObj('home', 'name')} size='small' autoComplete="off" fullWidth value={name} onChange={(e)=>setName(e.target.value)} required error={isSubmit && !name} helperText={(isSubmit && !name) ? contextObj('home', 'nameInvalid') : ""} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="contact-email" label={contextObj('home', 'email')} size='small' autoComplete="off" fullWidth type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required error={isSubmit && !email} helperText={(isSubmit && !email) ? contextObj('home', 'emailInvalid') : ""} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField id="contact-mobile" label={contextObj('home', 'mobile')} size='small' autoComplete="off" fullWidth type="tel" value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} required error={(isSubmit && !phoneNumber) || inValidPhoneNumber} helperText={(isSubmit && !phoneNumber) ? contextObj('home', 'phoneNumberInvalid') : inValidPhoneNumber ? contextObj('home', 'invalidMobile') : ""} />
                </Grid>
                <Grid item xs={12}>
                    <TextField id="contact-message" label={contextObj('home', 'message')} size='small' autoComplete="off" fullWidth multiline minRows={3} value={message} onChange={(e)=>setMessage(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant='contained' fullWidth type='submit'>{contextObj('common', 'send')}</Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default ContactForm;