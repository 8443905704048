import React from 'react';
import { Divider, Grid, LinearProgress, CircularProgress } from '@mui/material';

import useStyles from '../assets/styles';

const HomeLoading = () => {
    const classes = useStyles();

    return (
        <div style={{flex: 1}}>
            <div className="container mx-auto py-4">
                <div className='p-4'>
                    <LinearProgress color="inherit" className={`${classes.mainTextColor} max-w-xs h-4 rounded-md`} />
                    <Divider className='pt-1' />
                    <Grid container className='pt-16'>
                        <Grid item xs={12} className="flex justify-center">
                            <CircularProgress color="inherit" className={`${classes.mainTextColor}`} thickness={5} size="4rem" />
                        </Grid>
                        <Grid item xs={12} className="flex justify-center">
                            <span className="mt-4 font-semibold">Loading.....</span>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default HomeLoading;