import React from 'react';
import { Routes, Route } from "react-router-dom";
import { useTranslation, withTranslation } from 'react-i18next';

import AllRoutes from '../configs/AllRoutes';

const Router = (props) => {
    const { i18n } = props;
    const { t } = useTranslation();
    const defaultLang =  localStorage.getItem("lang") || process.env.REACT_APP_DEFAULT_LANG || 'en';
    const newDefaultLang = defaultLang.toLowerCase();

    if(i18n.language !== newDefaultLang){
        i18n.changeLanguage(newDefaultLang)
    }

    const contextObj = (key, objKey) => {
        return t(key, { returnObjects: true })[objKey];
    }

    return (
        <Routes>
            {AllRoutes?.map(routeObj => (
                <Route exact path={routeObj.url} key={routeObj.url || routeObj.page} element={!routeObj.layout ? 
                    <routeObj.page {...props} contextObj={contextObj} />
                    :
                    <routeObj.layout menuActiveKey={routeObj['activeKey']} contextObj={contextObj} i18n={i18n}><routeObj.page contextObj={contextObj} i18n={i18n} menuActiveKey={routeObj['activeKey']}/>
                </routeObj.layout>} />
            ))}
        </Routes>
    );
};

export default withTranslation()(Router);