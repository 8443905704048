import { CssBaseline } from '@mui/material';

const LoginLayout = ({children}) => {
    return (
        <main style={{minHeight: '100vh', backgroundColor: '#F0F2F5'}}>
            <CssBaseline />
            {children}
        </main>
    );
}

export default LoginLayout;