
import NoImageFront from '../assets/images/no-image-front.png';

const ImageWithOnErrorFrontend = (props) => {

    return (
        <img {...props} alt={props.alt} loading="lazy" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=NoImageFront; }} style={{background: `url(${NoImageFront})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} />
    )

}

export default ImageWithOnErrorFrontend;