import { useState, useEffect } from "react";
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate, useParams } from "react-router-dom";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, Typography, Paper, useMediaQuery, TextField, FormControl, InputLabel, Select, MenuItem, Button, FormHelperText } from '@mui/material';

import FileObject from '../utils/FileObject';
import Breadcrumb from '../components/breadcrumb';
import { openSnackbar } from '../reducers/notify';
import ImageWithOnError from '../components/imageWithOnError';
import { useGetCategoryListQuery } from "../reducers/category";
import { useGetProductObjQuery, useCreateProductMutation, useUpdateProductMutation } from "../reducers/product";

const API_URL = process.env.REACT_APP_API_URL;

const AdminProductDetailManagementPage = ({contextObj, i18n}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { productID } = useParams();
    const isCreateMode = (productID === 'create');
    const [ createProduct ] = useCreateProductMutation();
    const [ updateProduct ] = useUpdateProductMutation();
    const isSMUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const { data: categoryList, isError: catagoryObjError } = useGetCategoryListQuery();
    const { data: productObj, isError: productObjError, isLoading: productObjLoading, refetch: productRefetch } = useGetProductObjQuery(productID, { skip: isCreateMode });
    
    const [titleEN, setTitleEN] = useState('');
    const [titleTH, setTitleTH] = useState('');
    const [imageList, setImageList] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [categoryID, setCategoryID] = useState('');
    const [descriptionEN, setDescriptionEN] = useState('');
    const [descriptionTH, setDescriptionTH] = useState('');
    const [deletedImageList, setDeletedImageList] = useState([]);

    const breadcrumbList = [
        { title: contextObj('adminMenu', 'products'), link: "/admin-product" },
        { title: isCreateMode ? contextObj('adminProduct', 'createProduct') : (i18n.language === 'en') && productObj ? productObj['title_en'] : productObj ? productObj['title_th'] : '-' },
    ];

    useEffect(()=>{
        if((productObjError || catagoryObjError) && !isCreateMode){
            navigate('/admin-product');
        }
    }, [productObjError, catagoryObjError, isCreateMode, navigate]);

    useEffect(()=>{
        if(!isCreateMode && productObj){
            setDescriptionEN(productObj['description_en']);
            setDescriptionTH(productObj['description_th']);
            setCategoryID(productObj['category_id'] || '');
            setImageList(productObj['images'] || []);
        }
    }, [isCreateMode, productObj])

    useEffect(()=>{
        if(!isCreateMode && productObj && categoryList && productObj['category_id'] && !categoryList?.find(obj=>obj['id'] === productObj['category_id'])){
            productRefetch()
        }
    }, [categoryList, productObj, isCreateMode])

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);

        if(isCreateMode && (!titleEN || !titleTH)){
            return;
        }else if(!categoryID){
            return;
        }

        if(isCreateMode){
            createProduct({ categoryID, titleEN, titleTH, descriptionEN, descriptionTH, thumbnail: !thumbnail ? null : await Promise.all(thumbnail?.map(async (obj) => await FileObject.convertToBase64(obj))) }).then((res)=>{
                if(res.data?.statusCode === 201){
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                    navigate('/admin-product');
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }else{
            const genData = { categoryID, descriptionEN, descriptionTH, deletedImageList, thumbnail: !thumbnail ? null : await Promise.all(thumbnail?.map(async (obj) => await FileObject.convertToBase64(obj))) };
            updateProduct({productID: productID, initialPost: genData}).then((res)=>{
                if(res.data?.statusCode === 200){
                    setThumbnail(null);
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }
    }

    const handleDropBox = (files) => {
        setThumbnail(files);
    }

    const handleOnImageDelete = (imageObj) => {
        setImageList([...imageList].map(object => {
            if(object.id === imageObj.id) {
                return {...object, is_deleted: 1}
            }else{
                return object;
            }
        }));

        setDeletedImageList(state=>[...state, imageObj['id']]);
    }

    return (
        productObjLoading ? <></> :
        productObj || isCreateMode ?
        <form autoComplete="off" onSubmit={handleOnSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumb navigateList={breadcrumbList} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5' className='font-bold'>
                        {isCreateMode ? contextObj('adminProduct', 'createProduct') : (i18n.language === 'en') ? productObj['title_en'] : productObj['title_th']}
                    </Typography>
                </Grid>
                <Grid item xs={12} className='pt-2 sm:pt-4'>
                    <Grid container component={isSMUp ? Paper : null} className="sm:p-6 sm:pt-5">
                        <Grid item xs={12}>
                            <FormControl sx={{ minWidth: 120 }} size="small" fullWidth error={isSubmit && !categoryID} required>
                                <InputLabel id="category-select">{contextObj('adminMenu', 'category')}</InputLabel>
                                <Select labelId="category-select" id="category-select" value={categoryID} label={contextObj('adminMenu', 'category')} onChange={(e)=>setCategoryID(e.target.value)}>
                                    <MenuItem value="">
                                        {`${contextObj('adminCommon', 'pleaseSelect')}...`}
                                    </MenuItem>
                                    {categoryList?.map((categoryObj, index)=>(
                                        <MenuItem key={`category-item-${index}`} value={categoryObj['id']}>{i18n?.language === 'en' ? categoryObj['title_en'] : categoryObj['title_th']}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{(isSubmit && !categoryID) ? contextObj('adminProduct', 'invalidCategory') : null}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pr-2'>
                            <TextField label={`${contextObj('adminCommon', 'title')} (EN)`} id="product-title-en" defaultValue={isCreateMode ? titleEN : productObj['title_en']} onChange={e=>isCreateMode ? setTitleEN(e.target.value) : ()=>{}} error={isCreateMode && isSubmit && !titleEN} helperText={(isCreateMode && isSubmit && !titleEN) && `${contextObj('adminCommon', 'invalidTitle')} (EN)`} size="small" margin="normal" fullWidth required disabled={!isCreateMode} />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pl-2'>
                            <TextField label={`${contextObj('adminCommon', 'title')} (TH)`} id="product-title-th" defaultValue={isCreateMode ? titleTH : productObj['title_th']} onChange={e=>isCreateMode ? setTitleTH(e.target.value) : ()=>{}} error={isCreateMode && isSubmit && !titleTH} helperText={(isCreateMode && isSubmit && !titleTH) && `${contextObj('adminCommon', 'invalidTitle')} (TH)`} size="small" margin="normal" fullWidth required disabled={!isCreateMode} />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pr-2'>
                            <TextField label={`${contextObj('adminCommon', 'description')} (EN)`} id="product-description-en" defaultValue={descriptionEN} onChange={e=>setDescriptionEN(e.target.value)} size="small" margin="normal" fullWidth multiline minRows={5} maxRows={5} />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pl-2'>
                            <TextField label={`${contextObj('adminCommon', 'description')} (TH)`} id="product-description-th" defaultValue={descriptionTH} onChange={e=>setDescriptionTH(e.target.value)} size="small" margin="normal" fullWidth multiline minRows={5} maxRows={5} />
                        </Grid>
                        <Grid item xs={12}>
                            <small style={{color: 'rgba(0, 0, 0, 0.6)'}}>{contextObj('adminCategory', 'thumbnail')}</small>
                        </Grid>
                        <Grid item xs={12}>
                            <Dropzone accept={{'image/*': []}} multiple={true} maxFiles={10} onDrop={(files)=>handleDropBox(files)} >
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()} className={'py-4 px-6 text-center cursor-pointer w-full'} style={{color: 'grey', border: '2px dashed rgb(229, 231, 235)', borderStyle: 'dashed'}}>
                                        <input {...getInputProps()} />
                                        <CloudUploadIcon />&nbsp;&nbsp;<span>{contextObj("common", "dropFile")}</span>
                                    </div>
                                )}
                            </Dropzone>
                        </Grid>
                        {(thumbnail && thumbnail?.length > 0) ?
                            thumbnail?.map((obj, index)=>{
                                return(
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`product-thumbnail-${index}`} className='px-2'>
                                        <div className='mt-2 p-1 w-full rounded border border-solid border-gray-200'>
                                            <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                                                <ImageWithOnError src={URL.createObjectURL(obj)} alt={`product-thumbnail-${index}`} className='block w-full h-auto' onLoad={() => { URL.revokeObjectURL(obj) }} />
                                            </div>
                                            <small className='whitespace-nowrap overflow-ellipsis overflow-hidden mt-1 cursor-pointer text-gray-600' style={{display: 'inherit'}} onClick={()=>setThumbnail(null)} ><CancelIcon size='small' /> {obj['name']}</small>
                                        </div>
                                    </Grid>
                                )
                            })
                        : null}
                        {(imageList && imageList?.length > 0) ?
                            imageList?.map((obj, index)=>{
                                if(obj?.is_deleted === 0){
                                    return(
                                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`product-images-${index}`} className='px-2'>
                                            <div className='mt-2 p-1 w-full rounded border border-solid border-gray-200'>
                                                <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                                                    <ImageWithOnError src={API_URL+'images/'+obj['file_name']} alt={`product-images-${index}`} className='block w-full h-auto' onLoad={() => { URL.revokeObjectURL(API_URL+'images/'+obj['file_name']) }} />
                                                </div>
                                                <small className='whitespace-nowrap overflow-ellipsis overflow-hidden mt-1 cursor-pointer text-gray-600' style={{display: 'inherit'}} onClick={()=>handleOnImageDelete(obj)} ><CancelIcon size='small' /> {obj['file_name']}</small>
                                            </div>
                                        </Grid>
                                    )
                                }else{
                                    return null;
                                }
                            })
                        : null}
                        <Grid item xs={12} className='text-end pt-6'>
                            <Button type="submit" variant="contained" color="primary">
                                {contextObj('common', 'submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
        : null
    );
}

export default AdminProductDetailManagementPage;