import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate, useParams } from "react-router-dom";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, Typography, Paper, TextField, useMediaQuery, Button, IconButton } from '@mui/material';

import FileObject from '../utils/FileObject';
import Breadcrumb from '../components/breadcrumb';
import { openSnackbar } from '../reducers/notify';
import DataSingleton from '../configs/DataSingleton';
import ModalConfirm from '../components/modalConfirm';
import ImageWithOnError from '../components/imageWithOnError';
import { useGetCategoryObjQuery, useCreateCategoryMutation, useDeleteCategoryMutation, useUpdateCategoryMutation, useUpdateCategoryPopularMutation } from '../reducers/category';

const API_URL = process.env.REACT_APP_API_URL;

const AdminCategoryDetailManagement = (props) => {
    const { contextObj, i18n } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { categoryKey } = useParams();
    const isCreateMode = (categoryKey === 'create');
    const [ createCategory ] = useCreateCategoryMutation();
    const [ updateCategory ] = useUpdateCategoryMutation();
    const [ deleteCategory ] = useDeleteCategoryMutation();
    const [ updateCategoryPopular ] = useUpdateCategoryPopularMutation();
    const { data: categoryObj, isError: catalogObjError, isLoading: categoryObjLoading } = useGetCategoryObjQuery(categoryKey, { skip: isCreateMode });
    const isSMUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const [titleEN, setTitleEN] = useState("");
    const [titleTH, setTitleTH] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [isPopular, setIsPopular] = useState(false);
    const [thumbnailName, setThumbnailName] = useState("");
    const [descriptionEN, setDescriptionEN] = useState("");
    const [descriptionTH, setDescriptionTH] = useState("");
    const [openModalConfirm, setOpenModalConfirm] = useState(false);

    const breadcrumbList = [
        { title: contextObj('adminMenu', 'products'), link: "/admin-product" },
        { title: contextObj('adminMenu', 'category'), link: "/admin-category" },
        { title: isCreateMode ? contextObj('adminCategory', 'createCategory') : (i18n.language === 'en') && categoryObj ? categoryObj['title_en'] : categoryObj ? categoryObj['title_th'] : "-" },
    ];

    useEffect(()=>{
        if(catalogObjError && !isCreateMode){
            navigate('/admin-category');
        }
    }, [catalogObjError, isCreateMode, navigate]);

    useEffect(()=>{
        if(!isCreateMode && categoryObj){
            setDescriptionEN(categoryObj['description_en']);
            setDescriptionTH(categoryObj['description_th']);
            setThumbnailName(categoryObj['thumbnail']);
            setIsPopular(categoryObj['is_popular'] ? true : false);
        }
    }, [isCreateMode, categoryObj])

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);

        if(isCreateMode && (!titleEN || !titleTH)){
            return;
        }

        if(isCreateMode){
            createCategory({ titleEN, titleTH, descriptionEN, descriptionTH, thumbnail: await FileObject.convertToBase64(thumbnail) }).then((res)=>{
                if(res.data?.statusCode === 201){
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                    navigate('/admin-category');
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }else{
            const genData = { descriptionEN, descriptionTH, thumbnailName, thumbnail: await FileObject.convertToBase64(thumbnail) };
            updateCategory({categoryKey: categoryKey, initialPost: genData}).then((res)=>{
                if(res.data?.statusCode === 200){
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }
    }

    const handleOnDelete = () => {
        deleteCategory(categoryObj['id']).then((res)=>{
            if(res.data?.statusCode === 200){
                DataSingleton.IsProductNeedRefetch = true;
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                navigate('/admin-category');
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    const handleOnPuplar = (getPopularVal) => {
        updateCategoryPopular({categoryKey: categoryKey, initialPost: {isPopular: getPopularVal ? 1 : 0}}).then((res)=>{
            if(res.data?.statusCode === 200){
                setIsPopular(getPopularVal);
                setOpenModalConfirm(false);
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    return (
        categoryObjLoading ? <></> :
        categoryObj || isCreateMode ?
            <form autoComplete="off" onSubmit={handleOnSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumb navigateList={breadcrumbList} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5' className='font-bold'>
                            {isCreateMode ? contextObj('adminCategory', 'createCategory') : (i18n.language === 'en') ? categoryObj['title_en'] : categoryObj['title_th'] }
                            {isPopular && !isCreateMode ? 
                                <IconButton onClick={() => handleOnPuplar(!isPopular)} aria-label="category-popluar" className='text-amber-400 ml-0.5'>
                                    <StarIcon />
                                </IconButton>
                            : !isPopular && !isCreateMode ? 
                                <IconButton onClick={() => handleOnPuplar(!isPopular)} aria-label="category-popluar" className='ml-0.5'>
                                    <StarBorderIcon />
                                </IconButton>
                            : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className='pt-2 sm:pt-4'>
                        <Grid container component={isSMUp ? Paper : null} className="sm:p-6 sm:pt-5">
                            <Grid item xs={12} md={6} className='md:pr-2'>
                                <TextField label={`${contextObj('adminCommon', 'title')} (EN)`} id="category-title-en" defaultValue={isCreateMode ? titleEN : categoryObj['title_en']} onChange={e=>isCreateMode ? setTitleEN(e.target.value) : ()=>{}} error={isCreateMode && isSubmit && !titleEN} helperText={(isCreateMode && isSubmit && !titleEN) && `${contextObj('adminCommon', 'invalidTitle')} (EN)`} size="small" margin="normal" fullWidth required disabled={!isCreateMode} />
                            </Grid>
                            <Grid item xs={12} md={6} className='md:pl-2'>
                                <TextField label={`${contextObj('adminCommon', 'title')} (TH)`} id="category-title-th" defaultValue={isCreateMode ? titleTH : categoryObj['title_th']} onChange={e=>isCreateMode ? setTitleTH(e.target.value) : ()=>{}} error={isCreateMode && isSubmit && !titleTH} helperText={(isCreateMode && isSubmit && !titleTH) && `${contextObj('adminCommon', 'invalidTitle')} (TH)`} size="small" margin="normal" fullWidth required disabled={!isCreateMode} />
                            </Grid>
                            <Grid item xs={12} md={6} className='md:pr-2'>
                                <TextField label={`${contextObj('adminCommon', 'description')} (EN)`} id="category-description-en" defaultValue={descriptionEN} onChange={e=>setDescriptionEN(e.target.value)} size="small" margin="normal" fullWidth multiline minRows={5} maxRows={5} />
                            </Grid>
                            <Grid item xs={12} md={6} className='md:pl-2'>
                                <TextField label={`${contextObj('adminCommon', 'description')} (TH)`} id="category-description-th" defaultValue={descriptionTH} onChange={e=>setDescriptionTH(e.target.value)} size="small" margin="normal" fullWidth multiline minRows={5} maxRows={5} />
                            </Grid>
                            <Grid item xs={12}>
                                <small style={{color: 'rgba(0, 0, 0, 0.6)'}}>{contextObj('adminCategory', 'thumbnail')}</small>
                            </Grid>
                            <Grid item xs={12}>
                                <Dropzone accept={{'image/*': []}} multiple={false} maxFiles={1} onDrop={(files)=>setThumbnail(files[0])} >
                                    {({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()} className={'py-4 px-6 text-center cursor-pointer w-full'} style={{color: 'grey', border: '2px dashed rgb(229, 231, 235)', borderStyle: 'dashed'}}>
                                            <input {...getInputProps()} />
                                            <CloudUploadIcon />&nbsp;&nbsp;<span>{contextObj("common", "dropFile")}</span>
                                        </div>
                                    )}
                                </Dropzone>
                            </Grid>
                            {thumbnail ?
                                <Grid item xs={12}>
                                    <div className='mt-2 p-1 w-full max-w-xs rounded border border-solid border-gray-200'>
                                        <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                                            <ImageWithOnError src={URL.createObjectURL(thumbnail)} alt='catecory-thumbnail' className='block w-full h-auto' onLoad={() => { URL.revokeObjectURL(thumbnail) }} />
                                        </div>
                                        <small className='whitespace-nowrap overflow-ellipsis overflow-hidden mt-1 cursor-pointer text-gray-600' style={{display: 'inherit'}} onClick={()=>setThumbnail(null)} ><CancelIcon size='small' /> {thumbnail['name']}</small>
                                    </div>
                                </Grid>
                            : thumbnailName ?
                                <Grid item xs={12}>
                                    <div className='mt-2 p-1 w-full max-w-xs rounded border border-solid border-gray-200'>
                                        <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                                            <ImageWithOnError src={API_URL+'images/'+thumbnailName} alt='catecory-thumbnail' className='block w-full h-auto' onLoad={() => { URL.revokeObjectURL(API_URL+'images/'+thumbnailName) }} />
                                        </div>
                                        <small className='whitespace-nowrap overflow-ellipsis overflow-hidden mt-1 cursor-pointer text-gray-600' style={{display: 'inherit'}} onClick={()=>setThumbnailName(null)} ><CancelIcon size='small' /> {thumbnailName}</small>
                                    </div>
                                </Grid>
                            : null}
                        </Grid>
                    </Grid>
                    {!isCreateMode &&
                    <Grid item xs={6} className='pt-6'>
                        <Button variant="outlined" color="error" onClick={()=>setOpenModalConfirm(true)}>
                            {contextObj('common', 'delete')}
                        </Button>
                    </Grid> }
                    <Grid item xs={!isCreateMode ? 6 : 12} className='text-end pt-6'>
                        <Button type="submit" variant="contained" color="primary">
                            {contextObj('common', 'submit')}
                        </Button>
                    </Grid>
                </Grid>
                {!isCreateMode && <ModalConfirm open={openModalConfirm} handleClose={()=>setOpenModalConfirm(false)} handleConfirm={handleOnDelete} isDelete title='' description={`${contextObj('common', 'areYouSureDelete')} ${(i18n.language === 'en') ? categoryObj?.title_en || '' : categoryObj?.title_th || ''}?`} contextObj={contextObj} />}
            </form>
        : null
    );
}

export default AdminCategoryDetailManagement;