class Validator {

    static validateData = (value, inputType, rules = {}, fieldId) => {
        let isValid = true;
        let message = "";
        let trimmedValue = value.trim();

        if(rules.maxLength && trimmedValue.length > rules.maxLength){
            isValid = false;
            message = `${fieldId} must contain no more than ${rules.maxLength} characters.`;
        }

        if(rules.minLength && trimmedValue.length < rules.minLength){
            isValid = false;
            message = `${fieldId} must contain at most at least ${rules.minLength} characters.`;
        }

        if(rules.required && trimmedValue.length === 0){
            isValid = false;
            message = `Please enter ${fieldId}.`;
        }

        if(inputType === "email"){
            let emailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.].{0,}$");
            if(!emailRegex.test(trimmedValue)){
                isValid = false;
                message = "An email address must contain a single '@'.";
            }
        }

        if(inputType === "password" || inputType === "newPassword" || inputType === "currentPassword"){
            let strongPassword = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-])(\\S).{7,}$");
            if(!strongPassword.test(trimmedValue)){
                isValid = false;
                message = "Password must contain at least 8 characters with 1 Uppercase letter, 1 Lowercase Letter, 1 Number & 1 Symbol.";
            }
        }

        if(inputType === "phoneNumber"){
            let strongPhone = new RegExp("^[0]{1}?[0-9]{2}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{3,4}$");
            if(!strongPhone.test(trimmedValue)){
                isValid = false;
                message = "Please enter a valid Phone Number.";
            }
        }

        return { isValid, message };
    }

    static camalize(str) {
        return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()).trim().replace(/[^\w\s]/gi, '');
    }

    static checkIsSingleValueExist(checkData) {
        return checkData && String(checkData).length > 0;
    }

}

export default Validator