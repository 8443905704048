import { createTheme } from '@mui/material/styles';

import palette from './palette';

const THEME = createTheme({
    overrides: {
    },
    palette,
    typography: {
        fontFamily: [
          'Poppins',
          'Kanit',
          'Roboto',
          'Helvetica',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
    },
});

export default THEME;