import React, { useEffect, useState } from 'react';
import { Pagination } from "swiper";
import StarIcon from '@mui/icons-material/Star';
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Grid, Card, CardContent, Typography } from '@mui/material';

import useStyles from '../assets/styles';
import HomeLoading from '../components/homeLoading';
import NoImage from '../assets/images/no-image-front.png';
import { useGetSiteCategoryObjQuery } from '../reducers/category';
import ModalProductDetail from '../components/modalProductDetail';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const API_URL = process.env.REACT_APP_API_URL;

const ProducDeatilPage = ({contextObj, i18n}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { categoryKey } = useParams();
    const isEN = (i18n?.language === 'en');
    const [selectedTitle, setSelectedTitle] = useState("");
    const [selectedImagesList, setSelectedImagesList] = useState([]);
    const [selectedDescription, setSelectedDescription] = useState("");
    const [isProductDetailOpen, setIsProductDetailOpen] = useState(false);
    const { data: categoryObj, isError: catalogObjError, isLoading: categoryObjLoading } = useGetSiteCategoryObjQuery(categoryKey);

    const scaleCategoryTitle = () => {
        var scalable = document.querySelectorAll('.sec-scale--js');
        var margin = 10;
        for (var i = 0; i < scalable.length; i++) {
            var scalableContainer = scalable[i].parentNode;
            scalable[i].style.transform = 'scale(1)';
            var scalableContainerWidth = scalableContainer.offsetWidth - margin;
            var scalableWidth = scalable[i].offsetWidth + 20;
            scalable[i].style.transform = 'scale(' + scalableContainerWidth / scalableWidth + ')';
            scalableContainer.style.height = scalable[i].getBoundingClientRect().height + 'px';
        }
    }
    
    useEffect(()=>{
        scaleCategoryTitle();
        window.addEventListener("resize", scaleCategoryTitle);
    }, []);

    useEffect(()=>{
        scaleCategoryTitle();
    }, [categoryObj, isEN]);

    useEffect(()=>{
        if(catalogObjError){
            navigate('/product');
        }
    }, [catalogObjError, navigate]);

    const handleOnProductClick = (title, description, images) => {
        setIsProductDetailOpen(true)
        setSelectedTitle(title)
        setSelectedDescription(description)
        setSelectedImagesList(images)
    }

    const handleOnProductDetailClose = () => {
        setIsProductDetailOpen(false)
        setSelectedTitle('')
        setSelectedDescription('')
        setSelectedImagesList([])
    }

    return (
        categoryObjLoading ? <HomeLoading /> :
        categoryObj ?
            <div style={{flex: 1}}>
                <div className="container mx-auto py-4">
                    <div className='p-4'>
                        <span className={`${classes.mainTextColor} font-bold text-3xl cursor-default`}>
                            {isEN ? categoryObj['title_en'] : categoryObj['title_th']}
                            {categoryObj['is_popular'] ? <StarIcon className='text-amber-400 ml-1' /> : null}
                        </span>
                        <p className='pt-2 cursor-default'>
                            {isEN ? categoryObj['description_en'] : categoryObj['description_th']}
                        </p>
                        <Divider className='pt-1' />
                        <Grid container spacing={2} className='pt-4'>
                            {(categoryObj['products'] && (categoryObj['products']?.length > 0)) ?
                                categoryObj['products']?.map((product, pIndex)=>(
                                    <Grid item xs={12} sm={6} lg={4} key={`product-${product['product_key']}-${pIndex}`}>
                                        <Card className='h-full cursor-pointer' sx={{backgroundColor: `rgba(255, 255, 255, 0.7)`}}>
                                            <CardContent onClick={()=>handleOnProductClick(isEN ? product['title_en'] : product['title_th'], isEN ? product['description_en'] : product['description_th'], (product['images'] && (product['images']?.length >= 1)) ? product['images']?.map(obj => `${API_URL}images/${obj['file_name']}`) : categoryObj['thumbnail'] ? [`${API_URL}images/${categoryObj['thumbnail']}`] : [NoImage])} >
                                                {(product['images'] && (product['images']?.length > 1)) ?
                                                    <Swiper className='-mx-4 -mt-4 cursor-grab' loop={true} autoHeight={true} centeredSlides={true} pagination={{ clickable: true }} modules={[Pagination]} style={{ "--swiper-navigation-color": "#fff", "--swiper-pagination-color": "#fff" }} >
                                                        {product['images']?.map((imageObj, imageIndex)=>(
                                                            <SwiperSlide key={`product-image-${imageIndex}`} className='bg-no-repeat bg-center bg-cover' style={{minHeight: 250, backgroundColor: 'rgb(47, 76, 57)', backgroundImage: imageObj['file_name'] ? `url(${API_URL}images/${imageObj['file_name']})` : categoryObj['thumbnail'] ? `url(${API_URL}images/${categoryObj['thumbnail']})` : `url(${NoImage})` }} />
                                                        ))}
                                                    </Swiper>
                                                : (product['images'] && (product['images']?.length === 1)) ?
                                                    <div className='-mx-4 -mt-4 bg-no-repeat bg-center bg-cover' style={{minHeight: 250, backgroundColor: 'rgb(47, 76, 57)', backgroundImage: (product['images'][0] && product['images'][0]['file_name']) ? `url(${API_URL}images/${product['images'][0]['file_name']})` : categoryObj['thumbnail'] ? `url(${API_URL}images/${categoryObj['thumbnail']})` : `url(${NoImage})` }} />
                                                : <div className='-mx-4 -mt-4 bg-no-repeat bg-center bg-cover' style={{minHeight: 250, backgroundColor: 'rgb(47, 76, 57)', backgroundImage: categoryObj['thumbnail'] ? `url(${API_URL}images/${categoryObj['thumbnail']})` : `url(${NoImage})` }} />}
                                                <Typography gutterBottom variant="h5" component="div" className='pt-4'>
                                                    {isEN ? product['title_en'] : product['title_th']}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {isEN ? product['description_en'] : product['description_th']}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))
                            : 
                                <Grid item xs={12}>
                                    <div className='w-full bg-white bg-opacity-70 p-4 font-bold text-center rounded-lg shadow-sm uppercase'>
                                        {contextObj('common', 'noResultsFound')}
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
                <ModalProductDetail open={isProductDetailOpen} handleClose={()=>handleOnProductDetailClose(false)} title={selectedTitle} description={selectedDescription} images={selectedImagesList} contextObj={contextObj} />
            </div>
        : null
    );
}

export default ProducDeatilPage;