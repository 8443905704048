import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Typography, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import FileObject from '../utils/FileObject';
import { openSnackbar } from '../reducers/notify';
import ImageWithOnError from '../components/imageWithOnError';
import { useGetGalleryListQuery, useUpdateGalleryMutation } from '../reducers/gallery';

const API_URL = process.env.REACT_APP_API_URL;

const AdminGalleryManagementPage = ({contextObj}) => {
    const dispatch = useDispatch();
    const [ updateGallery ] = useUpdateGalleryMutation();
    const { data: galleryList } = useGetGalleryListQuery();

    const [imageList, setImageList] = useState([]);
    const [dataImageList, setDataImageList] = useState([]);
    const [deletedImageList, setDeletedImageList] = useState([]);

    useEffect(()=>{
        if(galleryList){
            setDataImageList(galleryList || []);
        }
    }, [galleryList])
    
    const handleDropBox = (files) => {
        setImageList(files);
    }

    const handleOnImageDelete = (imageObj) => {
        setImageList([...imageList]?.filter(object => object !== imageObj));
    }

    const handleOnImageDataDelete = (imageObj) => {
        setDataImageList([...dataImageList].map(object => {
            if(object.id === imageObj.id) {
                return {...object, is_deleted: 1}
            }else{
                return object;
            }
        }));

        setDeletedImageList(state=>[...state, imageObj['id']]);
    }

    const handleOnSubmit = async () => {
        updateGallery({ deletedImageList: deletedImageList, imageList: !imageList ? null : await Promise.all(imageList?.map(async (obj) => await FileObject.convertToBase64(obj))) }).then((res)=>{
            if(res.data?.statusCode === 200){
                setImageList([]);
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' className='font-bold'>Gallery</Typography>
            </Grid>
            <Grid item xs={12}>
                <Dropzone accept={{'image/*': []}} multiple={true} maxFiles={10} onDrop={(files)=>handleDropBox(files)} >
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} className={'py-4 px-6 text-center cursor-pointer w-full bg-white'} style={{color: 'grey', border: '2px dashed rgb(229, 231, 235)', borderStyle: 'dashed'}}>
                            <input {...getInputProps()} />
                            <CloudUploadIcon />&nbsp;&nbsp;<span>{contextObj("common", "dropFile")}</span>
                        </div>
                    )}
                </Dropzone>
            </Grid>
            {(imageList && imageList?.length > 0) ?
                imageList?.map((obj, index)=>{
                    return(
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`gallery-imageList-${index}`} className='px-2'>
                            <div className='mt-2 p-1 w-full rounded border border-solid border-gray-200'>
                                <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                                    <ImageWithOnError src={URL.createObjectURL(obj)} alt={`gallery-imageList-${index}`} className='block w-full h-auto' onLoad={() => { URL.revokeObjectURL(obj) }} />
                                </div>
                                <small className='whitespace-nowrap overflow-ellipsis overflow-hidden mt-1 cursor-pointer text-gray-600' style={{display: 'inherit'}} onClick={()=>handleOnImageDelete(obj)} ><CancelIcon size='small' /> {obj['name']}</small>
                            </div>
                        </Grid>
                    )
                })
            : null}
            {(dataImageList && dataImageList?.length > 0) ?
                dataImageList?.map((obj, index)=>{
                    if(obj?.is_deleted === 0){
                        return(
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`fallery-images-${index}`} className='px-2'>
                                <div className='mt-2 p-1 w-full rounded border border-solid border-gray-200'>
                                    <div style={{ display: 'flex', minWidth: 0, overflow: 'hidden' }}>
                                        <ImageWithOnError src={API_URL+'images/'+obj['file_name']} alt={`fallery-images-${index}`} className='block w-full h-auto' onLoad={() => { URL.revokeObjectURL(API_URL+'images/'+obj['file_name']) }} />
                                    </div>
                                    <small className='whitespace-nowrap overflow-ellipsis overflow-hidden mt-1 cursor-pointer text-gray-600' style={{display: 'inherit'}} onClick={()=>handleOnImageDataDelete(obj)} ><CancelIcon size='small' /> {obj['file_name']}</small>
                                </div>
                            </Grid>
                        )
                    }else{
                        return null;
                    }
                })
            : null}
            <Grid item xs={12} className='text-end pt-6'>
                <Button variant="contained" color="primary" onClick={handleOnSubmit}>
                    {contextObj('common', 'submit')}
                </Button>
            </Grid>
        </Grid>
    );
}

export default AdminGalleryManagementPage;