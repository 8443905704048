import * as React from 'react';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

const Breadcrumb = (props) => {
    const { navigateList = [] } = props;

    return (
        <Breadcrumbs aria-label="breadcrumb" className='py-2 px-4' style={{backgroundColor: 'white', maxWidth: '100%', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'}} >
            {navigateList?.map((obj, index)=>{
                const isLast = (navigateList.length-1 === index);
                if(isLast){
                    return (
                        <Typography key={`breadcrumb-${index}`} color="primary" className='cursor-default'>{obj['title']}</Typography>
                    )
                }else{
                    return (
                        <Link key={`breadcrumb-${index}`} underline="hover" color="inherit" to={obj['link']}>
                            {obj['title']}
                        </Link>
                    )
                }
            })}
        </Breadcrumbs>
    );
}

export default Breadcrumb;