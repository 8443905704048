import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, Typography, Paper, useMediaQuery, TextField, Button, Divider, InputAdornment, IconButton } from '@mui/material';

import Validator from "../utils/Validator";
import { openSnackbar } from '../reducers/notify';
import { useUpdateUserMutation } from '../reducers/user';

const AdminProfilePage = ({contextObj}) => {
    const dispatch = useDispatch();
    const [ updateUser ] = useUpdateUserMutation();
    const authUser = useSelector((state) => state.authUser)?.value;
    const isSMUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const [password, setPassword] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [inValidPassword, setInValidPassword] = useState(false);
    const [displayName, setDisplayName] = useState(authUser['displayName']);

    const onPasswordChange = (value) => {
        setPassword(value);
        setInValidPassword(false);
    }

    useEffect(()=>{
        if((authUser) && (displayName !== authUser['displayName'])){
            setDisplayName(authUser['displayName']);
        }
    }, [authUser]);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);

        if(!displayName){
            return;
        }else if((password || confirmPassword) && (password !== confirmPassword)){
            setConfirmPassword("");
            return;
        }else if(password && !Validator.validateData(password, 'password').isValid){
            setInValidPassword(true);
            return;
        }

        const genData = { displayName, password, currentPassword };
        updateUser({userID: authUser['userID'], initialPost: genData}).then((res)=>{
            if(res.data?.statusCode === 200){
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                setPassword("");
                setConfirmPassword("");
                setCurrentPassword("");
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    return (
        <form autoComplete="off" onSubmit={handleOnSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' className='font-bold'>
                        {contextObj('adminMenu', 'profile')}
                    </Typography>
                </Grid>
                <Grid item xs={12} className='pt-2 sm:pt-4'>
                    <Grid container component={isSMUp ? Paper : null} className="sm:p-6 sm:pt-5">
                        <Grid item xs={12} md={6} className='md:pr-2'>
                            <TextField label={contextObj('common', 'email')} value={authUser['userEmail'] || ''} size="small" margin="normal" fullWidth required autoComplete="off" disabled={true} />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pl-2'>
                            <TextField label={contextObj('adminUser', 'displayName')} value={displayName} onChange={e=>setDisplayName(e.target.value)} size="small" margin="normal" fullWidth autoComplete="off" required error={isSubmit && !displayName} helperText={isSubmit && !displayName ? contextObj('adminUser', 'invalidDisplayName') : ""} />
                        </Grid>
                        <Grid item xs={12} className='pt-2'>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} className='mt-4'>
                            <span className='font-bold'>
                                {contextObj('adminUser', 'changePassword')}
                            </span>
                        </Grid>
                        <Grid item xs={12} className='md:pr-2'>
                            <TextField label={contextObj('adminUser', 'currentPassword')} size="small" margin="normal" autoComplete="off" fullWidth value={currentPassword} onChange={(e)=>setCurrentPassword(e.target.value)} type={isShowPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle old password visibility" onClick={()=>setIsShowPassword(!isShowPassword)} onMouseDown={e=>e.preventDefault()} edge="end">
                                                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pr-2'>
                            <TextField label={contextObj('adminUser', 'newPassword')} size="small" margin="normal" autoComplete="off" fullWidth value={password} onChange={(e)=>onPasswordChange(e.target.value)} type={isShowPassword ? "text" : "password"} error={inValidPassword} helperText={inValidPassword ? contextObj('adminUser', 'passwordInvalid') : ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={()=>setIsShowPassword(!isShowPassword)} onMouseDown={e=>e.preventDefault()} edge="end">
                                                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pl-2'>
                            <TextField label={contextObj('adminUser', 'confirmPassword')} size="small" margin="normal" autoComplete="off" fullWidth value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} type={isShowPassword ? "text" : "password"} error={(password !== confirmPassword)} helperText={(password !== confirmPassword) ? contextObj('register', 'passwordInvalid3') : ""} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='text-end pt-6'>
                    <Button type="submit" variant="contained" color="primary">
                        {contextObj('common', 'submit')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default AdminProfilePage;