import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Grid, Typography, Button, ButtonGroup } from '@mui/material';

import { openSnackbar } from '../reducers/notify';
import SearchTextField from '../components/search';
import ModalConfirm from '../components/modalConfirm';
import EnhancedTable from '../components/enhanceTable';
import { useGetUserListQuery, useDeleteUserMutation } from '../reducers/user';

const AdminUserManagementPage = ({contextObj, i18n}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ deleteUser ] = useDeleteUserMutation();
    const { data: userList } = useGetUserListQuery();
    const [quickSearchVal, setQuickSearchVal] = useState('');
    const [rowSelectedList, setRowSelectedList] = useState([]);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const authUser = useSelector((state) => state.authUser)?.value;

    const headCells = [
        { id: 'email', align: "left", numeric: false, label: contextObj('common', 'email') },
        { id: 'display_name', align: "left", numeric: false, label: contextObj('adminUser', 'displayName') },
        { id: 'last_login', align: "left", numeric: false, label: contextObj('adminUser', 'lastLogin'), isDate: true, dateFormat: 'DD/MM/yyyy HH:mm:ss' },
    ];

    const handleOnSearch = (val) => {
        setRowSelectedList([]);
        setQuickSearchVal(val)
    }

    const handleOnRowClick = (user) => {
        if(authUser['userID'] === user['id']){
            navigate(`/admin-profile`);
        }else{
            navigate(`/admin-user/${user['id']}`);
        }
    }

    const onSeletcTableRow = (result) => {
        setRowSelectedList(result)
    }

    const handleOnDelete = () => {
        if(rowSelectedList && (rowSelectedList?.length > 0)){
            const deleteUserList = rowSelectedList?.map(obj=>obj['id'])?.filter(item=>item!==authUser['userID']);
            deleteUser({deleteUserList: deleteUserList}).then((res)=>{
                if(res.data?.statusCode === 200){
                    setRowSelectedList([]);
                    setOpenModalConfirm(false);
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' className='font-bold'>{contextObj('adminMenu', 'users')}</Typography>
            </Grid>
            <Grid item xs={5} className='hidden sm:block'>
                <SearchTextField searchVal={quickSearchVal} setSearchVal={handleOnSearch} contextObj={contextObj} />
            </Grid>
            <Grid item xs={12} sm={7} className="text-end">
                {(rowSelectedList.length >= 1) ?
                    <ButtonGroup>
                        <Button className={"border-2 text-gray-500 border-gray-500"} onClick={()=>setRowSelectedList([])}>{contextObj('common', 'cancel')}</Button>
                        <Button className="border-2 border-red-500 text-red-500" startIcon={<DeleteOutlinedIcon fontSize="small"/>} onClick={()=>setOpenModalConfirm(true)}>{contextObj('common', 'delete')}</Button>
                    </ButtonGroup>
                :
                    <ButtonGroup>
                        <Button component={Link} to={'/admin-user/create'} variant="outlined" className={"border-2"} startIcon={<AddIcon fontSize="small"/>}>{contextObj('adminMenu', 'addUser')}</Button>
                    </ButtonGroup>
                }
            </Grid>
            <Grid item xs={12} className='block sm:hidden'>
                <SearchTextField searchVal={quickSearchVal} setSearchVal={handleOnSearch} contextObj={contextObj} />
            </Grid>
            <Grid item xs={12}>
                <EnhancedTable headCellList={headCells} tableDataList={userList?.filter(obj=>{return String(obj['email'].toLowerCase()).includes(quickSearchVal.toLowerCase()) || String(obj['display_name'].toLowerCase()).includes(quickSearchVal.toLowerCase())})} isCheckBox={true} rowSelectedList={rowSelectedList} handleRowSeletion={onSeletcTableRow} handleTableClick={handleOnRowClick} contextObj={contextObj} isTH={(i18n.language === 'th')} />
            </Grid>
            <ModalConfirm open={openModalConfirm} handleClose={()=>setOpenModalConfirm(false)} handleConfirm={handleOnDelete} isDelete title='' description={`${contextObj('common', 'areYouSureDelete')} ${rowSelectedList?.filter(item=>item['id']!==authUser['userID'])?.map(obj => obj?.display_name)?.join(', ')}?`} contextObj={contextObj} />
        </Grid>
    );
}

export default AdminUserManagementPage;