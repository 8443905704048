import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel, { tableSortLabelClasses } from '@mui/material/TableSortLabel';
import { Box, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Paper, Checkbox } from '@mui/material';

import useStyles from '../assets/styles';
import DateObject from '../utils/DateObject';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    }
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
    [`&.${tableSortLabelClasses.root}`]: {
        color: 'white',
        '&:hover': {
            color: 'white',
        }
    },
    [`&.${tableSortLabelClasses.active}`]: {
        color: 'white',
        '&:hover': {
            color: 'white',
        }
    },
    [`&.${tableSortLabelClasses.icon}`]: {
        color: 'white',
    }
}));

function descendingComparator(a, b, orderBy) {
    if (!b[orderBy]) { return -1 }
    if (!a[orderBy]) { return 1 }
    if (b[orderBy] < a[orderBy]) { return -1 }
    if (b[orderBy] > a[orderBy]) { return 1 }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells = [], isCheckBox, classes } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <StyledTableCell className={classes.customTableCellBorder} padding="checkbox" align={isCheckBox ? "inherit" : "center"}>
                    {isCheckBox ? (
                        <Checkbox className="text-white" indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all desserts' }} />
                    ) : <h6 className="text-white">&nbsp;&nbsp;#&nbsp;&nbsp;</h6> }
                </StyledTableCell>
                {headCells.map((headCell, index) => (
                    <StyledTableCell className={index === (headCells.length-1) ? classes.customTableCellBorderLast : classes.customTableCellBorder} key={headCell.id} align={headCell.align || 'left'} padding={'normal'} sortDirection={(orderBy === headCell.sortKey) || (orderBy === headCell.id) ? order : false}>
                        <StyledTableSortLabel active={(orderBy === headCell.sortKey) || (orderBy === headCell.id)} direction={(orderBy === headCell.sortKey) || (orderBy === headCell.id) ? order : 'asc'} onClick={createSortHandler(headCell.sortKey || headCell.id)} >
                            {headCell.align === 'center' ? <span style={{marginLeft: '26px', whiteSpace: 'nowrap'}}>{headCell.label}</span> : headCell.label}
                        </StyledTableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTable = (props) => {
    const { headCellList = [], tableDataList = [], isCheckBox = false, handleTableClick = () => { }, rowSelectedList = [], handleRowSeletion = () => {}, contextObj, isTH = false } = props;
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [selected, setSelected] = useState(rowSelectedList);

    useEffect(() => {
        if(rowSelectedList.length !== selected.length) {
            setSelected(rowSelectedList)
        }
    }, [rowSelectedList, selected])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        let newItemSelectedList = [];
        if(event.target.checked) {
            newItemSelectedList = tableDataList.map((n) => n);
        }
        setSelected(newItemSelectedList);
        handleRowSeletion(newItemSelectedList);
    };

    const handleSelectionClick = (event, rowDataObj) => {
        const selectedIndex = selected.indexOf(rowDataObj);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, rowDataObj);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        handleRowSeletion(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (rowDataObj) => selected.indexOf(rowDataObj) !== -1;
    
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', maxWidth: `calc(100vw - 32px)`, overflowX: 'auto' }}>
                <TableContainer className="rounded-t">
                    <Table sx={{ minWidth: 200, width: '100%' }} aria-labelledby="tableTitle" size={'medium'}>
                        <EnhancedTableHead headCells={headCellList} numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={tableDataList.length} isCheckBox={isCheckBox} classes={classes} />
                        {!tableDataList || tableDataList.length < 1 ?
                            <TableBody>
                                <TableRow hover role="checkbox">
                                    <TableCell colSpan={!headCellList ? 1 : headCellList.length + 1} component="th" scope="row" align={'center'}>
                                        {contextObj('enhancedTable', 'resultNotFound')}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        :
                            <TableBody>
                                {stableSort(tableDataList, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                    const isItemSelected = isSelected(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={`table-row-${index}`} selected={isItemSelected}>
                                            <TableCell onClick={(event) => isCheckBox ? handleSelectionClick(event, row) : handleTableClick(row)} padding="checkbox" align={isCheckBox ? "inherit" : "center"} className={classes.customTableCellBorder}>
                                                {isCheckBox ? <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} /> : (index + 1) + (page * rowsPerPage)}
                                            </TableCell>
                                            {headCellList.map((headCell, index) => {
                                                const isClickDisabled = headCell?.clickDisabled;
                                                let rowValue = row[headCell.id];
                                                if(!rowValue && !headCell.isNumeric){ 
                                                    rowValue = "-"
                                                }else if(headCell.isDate){
                                                    rowValue = DateObject.convertDateTime(rowValue, headCell.dateFormat, !isTH) 
                                                }
                                                const clickableColumnClass = isClickDisabled ? ' cursor-default' : ' cursor-pointer';
                                                return (
                                                    <TableCell onClick={(e) => isClickDisabled ? ()=>{} : handleTableClick(row)} key={index} id={labelId} component="th" scope="row" padding={headCell['padding'] || 'normal'} align={headCell['align'] || 'left'} className={index === (headCellList.length-1) ? classes.customTableCellBorderLast+clickableColumnClass : classes.customTableCellBorder+clickableColumnClass}>
                                                        {rowValue}
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={[5, 10, 15, 30, 50]} labelRowsPerPage={contextObj('enhancedTable', 'rowsPerPage')} component="div" count={tableDataList.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </Paper>
        </Box>
    );
}

export default EnhancedTable;