import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeSnackbar } from '../reducers/notify';

const Snackbar = () => {
    const dispatch = useDispatch();
    const [displayed, setDisplayed] = useState([]);
    const notify = useSelector((state) => state.notify);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = id => { setDisplayed(prevDisplayed => [...prevDisplayed, id]); };
    const removeDisplayed = id => {
        setDisplayed(prevDisplayed => [...prevDisplayed.filter(key => id !== key)]);
    };

    useEffect(() => {
        (notify.value || []).forEach(
            ({ key, message, options = {}, dismissed = false }) => {
                if (dismissed) return closeSnackbar(key);
                if (displayed.includes(key)) return;
                enqueueSnackbar(message, {
                    key,
                    ...options,
                    onClose: (event, reason, key) => {
                        if (options.onClose) { options.onClose(event, reason, key) }
                    },
                    onExited: (event, key) => {
                        dispatch(removeSnackbar(key));
                        removeDisplayed(key);
                    }
                });
                storeDisplayed(key);
            }
        );
    }, [notify, closeSnackbar, dispatch, displayed, enqueueSnackbar]);

    return null;
};

export default Snackbar;