import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled, useTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CollectionsIcon from '@mui/icons-material/Collections';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useLocation, Link, useNavigate } from "react-router-dom";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { Box, Toolbar, List, CssBaseline, Typography, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Grow, useMediaQuery, Badge } from '@mui/material';

import useStyles from '../assets/styles';
import Footer from '../components/footer';
import LogoutService from '../reducers/logout';
import FlagTH from '../assets/images/flag-th-32.png';
import FlagEN from '../assets/images/flag-en-32.png';
import { useGetAuthUserQuery } from "../reducers/user";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
}));

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const AdminLayout = ({children, i18n, contextObj, menuActiveKey}) => {
    const classes = useStyles();
    const contactUnRead = useSelector((state) => state.contactUnRead)?.value;

    const adminMenus = [
        {key: 'admin-dashboard', title: contextObj('adminMenu', 'dashboard'), link: '/admin-dashboard', icon: <DashboardIcon />},
        {key: 'admin-product', title: contextObj('adminMenu', 'products'), link: '/admin-product', icon: <LocalOfferIcon />},
        {key: 'admin-gallery', title: contextObj('adminMenu', 'gallery'), link: '/admin-gallery', icon: <CollectionsIcon />},
        {key: 'admin-users', title: contextObj('adminMenu', 'users'), link: '/admin-user', icon: <SupervisedUserCircleIcon />},
        {key: 'admin-contact', title: contextObj('adminMenu', 'contacts'), link: '/admin-contact', icon: <ContactPhoneIcon />},
    ];
    
    const authMenus = [
        {key: 'admin-profile', title: contextObj('adminMenu', 'profile'), link: '/admin-profile', icon: <AccountCircleIcon />},
        {key: 'admin-logout', title: contextObj('adminMenu', 'logout'), link: '/', icon: <LogoutIcon />},
    ];
    
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:849.99px)');
    const isMobileUp = useMediaQuery('(min-width:850px)');
    const isLGDown = useMediaQuery(theme.breakpoints.down('lg'));
    const [open, setOpen] = useState(!isLGDown);
    const { data: authUser, error: authUserError, refetch: authRefetch } = useGetAuthUserQuery();

    useEffect(() => {
        if(authUser){
            authRefetch();
        }

        if((authUser && (authUser?.statusCode !== 200)) || authUserError){
            navigate("/admin-login");
        }
    }, [location, authUserError]);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    
    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    const handleChangeLang = () => {
        if(i18n.language === 'en'){
            i18n.changeLanguage('th')
            localStorage.setItem('lang', 'th');
        }else{
            i18n.changeLanguage('en')
            localStorage.setItem('lang', 'en');
        }
    }

    const handleLogout = async () => {
        if(isLGDown){
            handleDrawerClose();
        }
        await LogoutService.logoutUser().then(() => {
            navigate("/admin-login");
        })
    }

    return (
        <main style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#F0F2F5'}}>
            <CssBaseline />
            {!isMobileUp && <MuiDrawer variant="temporary" ModalProps={{ keepMounted: true }} open={open} onClose={handleDrawerClose}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {adminMenus.map((item, index)=>(
                        <ListItem key={`admin-menu${index}-${item['key']}`} disablePadding component={Link} to={item['link']} onClick={isLGDown ? handleDrawerClose : ()=>{}} sx={{ display: 'block' }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                    <div className={(menuActiveKey === item['key']) ? `${classes.mainBGColor} p-2 rounded-lg text-white` : "p-2"}>
                                        {(item['key'] === 'admin-contact') ?
                                            <Badge color="error" badgeContent={contactUnRead} max={99}>
                                                {item['icon']}
                                            </Badge>
                                        : item['icon']}
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={item['title']} sx={{ opacity: open ? 1 : 0, color: (menuActiveKey === item['key']) ? 'primary.main' : 'inherit', textDecorationLine: (menuActiveKey === item['key']) ? 'underline' : 'unset', textDecorationStyle: 'wavy', textUnderlineOffset: 3 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List className='mb-16'>
                    {authMenus.map((item, index) => (
                            <ListItem key={`auth-menu${index}-${item['key']}`} disablePadding component={Link} to={(item['key'] === 'admin-logout') ? "" : item['link']} onClick={(item['key'] === 'admin-logout') ? handleLogout : isLGDown ? handleDrawerClose : ()=>{}} sx={{ display: 'block' }}>
                            <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, }}>
                                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                    <div className={(menuActiveKey === item['key']) ? `${classes.mainBGColor} p-2 rounded-lg text-white` : "p-2"}>
                                        {item['icon']}
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={item['title']} sx={{ opacity: open ? 1 : 0, color: (menuActiveKey === item['key']) ? 'primary.main' : 'inherit', textDecorationLine: (menuActiveKey === item['key']) ? 'underline' : 'unset', textDecorationStyle: 'wavy', textUnderlineOffset: 3 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </MuiDrawer> }
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton color="inherit" aria-label="open admin drawer" onClick={open ? handleDrawerClose : handleDrawerOpen} edge="start" sx={{ marginRight: 3 }}>
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: 1, display: 'flex' }}>
                            <Typography variant="h6" noWrap component="div">
                                VANDASEEDS ADMIN
                            </Typography>
                        </Box>
                        <Tooltip title="Change Language">
                            <IconButton onClick={handleChangeLang} sx={{ p: 0 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Grow in={(i18n.language === 'th')}>
                                        <img alt="th lang" src={FlagTH} className={(i18n.language === 'th') ? 'block rounded-lg' : "hidden"} style={{width: 26, height: 26}} loading="lazy"/>
                                    </Grow>
                                    <Grow in={(i18n.language === 'en')}>
                                        <img alt="en lang" src={FlagEN} className={(i18n.language === 'en') ? 'block rounded-lg' : "hidden"} style={{width: 26, height: 26}} loading="lazy"/>
                                    </Grow>
                                </Box>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                {!isMobile && <StyledDrawer variant="permanent" open={isMobileUp ? open : false}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>
                        {adminMenus.map((item, index)=>(
                            <ListItem key={`admin-menu${index}-${item['key']}`} disablePadding component={Link} to={item['link']} onClick={isLGDown ? handleDrawerClose : ()=>{}} sx={{ display: 'block' }}>
                                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: {xs: 1, sm: 1.5}, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                        <div className={(menuActiveKey === item['key']) ? `${classes.mainBGColor} p-2 rounded-lg text-white` : "p-2"}>
                                            {(item['key'] === 'admin-contact') ?
                                                <Badge color="error" badgeContent={contactUnRead} max={99}>
                                                    {item['icon']}
                                                </Badge>
                                            : item['icon']}
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText primary={item['title']} sx={{ opacity: open ? 1 : 0, color: (menuActiveKey === item['key']) ? 'primary.main' : 'inherit', textDecorationLine: (menuActiveKey === item['key']) ? 'underline' : 'unset', textDecorationStyle: 'wavy', textUnderlineOffset: 3 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    <List className='mb-16'>
                        {authMenus.map((item, index) => (
                             <ListItem key={`auth-menu${index}-${item['key']}`} disablePadding component={Link} to={(item['key'] === 'admin-logout') ? "" : item['link']} onClick={(item['key'] === 'admin-logout') ? handleLogout : isLGDown ? handleDrawerClose : ()=>{}} sx={{ display: 'block' }}>
                                <ListItemButton sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: {xs: 1, sm: 1.5}, }}>
                                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center' }}>
                                        <div className={(menuActiveKey === item['key']) ? `${classes.mainBGColor} p-2 rounded-lg text-white` : "p-2"}>
                                            {item['icon']}
                                        </div>
                                    </ListItemIcon>
                                    <ListItemText primary={item['title']} sx={{ opacity: open ? 1 : 0, color: (menuActiveKey === item['key']) ? 'primary.main' : 'inherit', textDecorationLine: (menuActiveKey === item['key']) ? 'underline' : 'unset', textDecorationStyle: 'wavy', textUnderlineOffset: 3 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </StyledDrawer> }
                <Box component="main" sx={{ flexGrow: 1, p: {xs: 2, md: 3} }}>
                    <DrawerHeader />
                    {authUser && children}
                </Box>
            </Box>
            <Footer i18n={i18n} contextObj={contextObj} />
        </main>
    );
}

export default AdminLayout;