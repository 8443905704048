import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { EffectFade, Pagination, Autoplay } from "swiper";

import BG1 from '../assets/images/bg-1.jpg';
import BG2 from '../assets/images/bg-2.jpg';
import BG3 from '../assets/images/bg-3.jpg';

const Carousel = () => {
    return (
        <div>
            <Swiper
                spaceBetween={30}
                loop={true}
                effect={"fade"}
                centeredSlides={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{ clickable: true, renderBullet: function (index, className) { return '<span class="' + className + ' bg-green-800"></span>'; } }}
                modules={[EffectFade, Autoplay, Pagination]}
                autoHeight={true}
                style={{maxHeight: 500}}
                className='cursor-grab'
            >
                <SwiperSlide>
                    <img alt="vandaseeds-cariusel-1" className="w-full" src={BG1} loading="lazy"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img alt="vandaseeds-cariusel-2" className="w-full" src={BG2} loading="lazy"/>
                </SwiperSlide>
                <SwiperSlide>
                    <img alt="vandaseeds-cariusel-4" className="w-full" src={BG3} loading="lazy"/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Carousel;