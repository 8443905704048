import React, { Suspense } from 'react';
import Slide from '@mui/material/Slide';
import { SnackbarProvider } from 'notistack';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import Routes from './routes';
import THEME from './assets/theme';
import BackDrop from './components/backDrop';
import Snackbar from './components/snackbar';

function App() {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={Slide} autoHideDuration={2000} >
            <ThemeProvider theme={THEME}>
                <HashRouter>
                    <Snackbar />
                    <BackDrop />
                    <Suspense>
                        <Routes />
                    </Suspense>
                </HashRouter>
            </ThemeProvider>
        </SnackbarProvider>
    );
}

export default App;