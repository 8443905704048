import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, Link } from "react-router-dom";
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Grid, Typography, Button, ButtonGroup } from '@mui/material';

import { openSnackbar } from '../reducers/notify';
import SearchTextField from '../components/search';
import DataSingleton from '../configs/DataSingleton';
import ModalConfirm from '../components/modalConfirm';
import EnhancedTable from '../components/enhanceTable';
import { useGetProductListQuery, useDeleteProductMutation } from '../reducers/product';

const AdminProductManagementPage = ({contextObj, i18n}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ deleteProduct ] = useDeleteProductMutation();
    const [quickSearchVal, setQuickSearchVal] = useState('');
    const [rowSelectedList, setRowSelectedList] = useState([]);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const { data: productList, refetch: refetchProductList } = useGetProductListQuery();

    const headCells = [
        { id: 'title_en', align: "left", numeric: false, label: contextObj('adminCommon', 'title')+' (EN)' },
        { id: 'title_th', align: "left", numeric: false, label: contextObj('adminCommon', 'title')+' (TH)' },
        { id: (i18n.language === 'en') ? 'category_title_en' : 'category_title_th', align: "left", numeric: false, label: contextObj('adminProduct', 'categoryTitle') },
        { id: 'updated_at', align: "left", numeric: false, label: contextObj('adminCommon', 'lastUpdate'), isDate: true, dateFormat: 'DD/MM/yyyy HH:mm:ss' },
    ];

    useEffect(()=>{
        if(DataSingleton.IsProductNeedRefetch){
            DataSingleton.IsProductNeedRefetch = false;
            refetchProductList();
        }
    }, []);

    const onSeletcTableRow = (result) => {
        setRowSelectedList(result)
    }

    const handleOnSearch = (val) => {
        setRowSelectedList([]);
        setQuickSearchVal(val)
    }

    const handleOnRowClick = (product) => {
        navigate(`/admin-product/${product['id']}`);
    }

    const handleOnDelete = () => {
        if(rowSelectedList && (rowSelectedList?.length > 0)){
            const deleteProductList = rowSelectedList?.map(obj=>obj['id']);
            deleteProduct({deleteProductList: deleteProductList}).then((res)=>{
                if(res.data?.statusCode === 200){
                    setRowSelectedList([]);
                    setOpenModalConfirm(false);
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' className='font-bold'>{contextObj('adminMenu', 'products')}</Typography>
            </Grid>
            <Grid item xs={5} className='hidden sm:block'>
                <SearchTextField searchVal={quickSearchVal} setSearchVal={handleOnSearch} contextObj={contextObj} />
            </Grid>
            <Grid item xs={12} sm={7} className="text-end">
                {(rowSelectedList.length >= 1) ?
                    <ButtonGroup>
                        <Button className={"border-2 text-gray-500 border-gray-500"} onClick={()=>setRowSelectedList([])}>{contextObj('common', 'cancel')}</Button>
                        <Button className="border-2 border-red-500 text-red-500" startIcon={<DeleteOutlinedIcon fontSize="small"/>} onClick={()=>setOpenModalConfirm(true)}>{contextObj('common', 'delete')}</Button>
                    </ButtonGroup>
                :
                    <ButtonGroup>
                        <Button component={Link} to={'/admin-product/create'} variant="outlined" className={"border-2"} startIcon={<AddIcon fontSize="small"/>}>{contextObj('adminMenu', 'addProduct')}</Button>
                        <Button component={Link} to={'/admin-category'} variant="outlined" className={"border-2"} startIcon={<ListAltIcon fontSize="small"/>}>{contextObj('adminMenu', 'category')}</Button>
                    </ButtonGroup>
                }
            </Grid>
            <Grid item xs={12} className='block sm:hidden'>
                <SearchTextField searchVal={quickSearchVal} setSearchVal={handleOnSearch} contextObj={contextObj} />
            </Grid>
            <Grid item xs={12}>
                <EnhancedTable headCellList={headCells} tableDataList={productList?.filter(obj=>{return String(obj['title_th'].toLowerCase()).includes(quickSearchVal.toLowerCase()) || String(obj['title_en'].toLowerCase()).includes(quickSearchVal.toLowerCase())})} isCheckBox={true} rowSelectedList={rowSelectedList} handleRowSeletion={onSeletcTableRow} handleTableClick={handleOnRowClick} contextObj={contextObj} isTH={(i18n.language === 'th')} />
            </Grid>
            <ModalConfirm open={openModalConfirm} handleClose={()=>setOpenModalConfirm(false)} handleConfirm={handleOnDelete} isDelete title='' description={`${contextObj('common', 'areYouSureDelete')} ${rowSelectedList?.map(obj => (i18n.language === 'en') ? obj?.title_en : obj?.title_th)?.join(', ')}?`} contextObj={contextObj} />
        </Grid>
    );
}

export default AdminProductManagementPage;