import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Typography, Paper, TextField, InputAdornment, IconButton, Grid, Button } from '@mui/material';

import Validator from '../utils/Validator';
import { openSnackbar } from '../reducers/notify';
import Logo from '../assets/images/vanda-logo.png';
import { useResetPasswordMutation } from '../reducers/user';

const ResetPasswordPage = ({contextObj}) => {
    const logoRef = useRef(null);
    const titleRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { resetToken } = useParams();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [titleHeight, calTitleHeight] = useState(0);
    const [ resetpassword ] = useResetPasswordMutation();
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [inValidPassword, setInValidPassword] = useState(false);

    useEffect(()=>{
        window.addEventListener("resize", resizeWindow);
    }, [])
    
    const resizeWindow = () => {
        if(window.innerWidth <= 599.99){
            calTitleHeight(logoRef?.current?.offsetHeight + titleRef?.current?.offsetHeight)
        }
    }
    
    const onImgLoad = ({ target: img }) => {
        const { offsetHeight } = img;
        calTitleHeight(titleRef?.current?.offsetHeight + offsetHeight)
    };
    
    const handleOnForgot = (e) => {
        e.preventDefault();
        if(!email || !password){
            setIsSubmit(true);
            return;
        }else if(password && !Validator.validateData(password, 'password').isValid){
            setInValidPassword(true);
            return;
        }

        resetpassword({ resetToken: resetToken, initialPost: {email: email, password: password} }).then((res)=>{
            if(res.data?.statusCode === 200){
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                navigate("/admin-dashboard");
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    return (
        <div className="flex flex-col items-center sm:justify-center min-h-screen pt-6 sm:p-6">
            <img ref={logoRef} onLoad={onImgLoad} alt="VANDASEEDS" src={Logo} style={{width: '50vw', maxWidth: 200}} />
            <Typography ref={titleRef} variant={"h4"} className='font-bold pt-4 pb-9' style={{color: '#050505'}}>VANDASEEDS</Typography>
            <form autoComplete="off" onSubmit={handleOnForgot}>
                <Paper className='container max-w-screen-sm px-8 py-6' sx={{ minHeight: { xs: `calc(100vh - ${titleHeight}px - 1.5rem)`, sm: 'unset' }, boxShadow: { xs: '0px -15px 20px -15px rgba(0,0,0,0.2)', sm: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)' }, borderRadius: { xs: '20px 20px 0px 0px', sm: 'max(0px, min(8px, calc((100vw - 4px - 100%) * 9999)))/8px' } }}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant={"h5"} className='font-bold pb-6' style={{color: '#050505'}}>{contextObj('forgotAndResetPassword', 'resetPassword')?.toUpperCase()}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="admin-email1" type="email" label={contextObj('common', 'email')} variant="outlined" fullWidth size='small' margin="dense" value={email} onChange={e=>setEmail(e.target.value)} required error={isSubmit && !email} helperText={(isSubmit && !email) ? `${contextObj('adminUser', 'emailInvalid')}` : null} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="admin-password2" label={contextObj('adminUser', 'newPassword')} variant="outlined" fullWidth size='small' margin="dense" type={isShowPassword ? "text" : "password"} value={password} onChange={e=>setPassword(e.target.value)} required error={(isSubmit && !password) || inValidPassword} helperText={((isSubmit && !password) || inValidPassword) ? `${contextObj('adminUser', 'passwordInvalid')}` : null}
                                InputProps={{endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={()=>setIsShowPassword(!isShowPassword)} onMouseDown={e=>e.preventDefault()} edge="end">
                                            {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
                            />
                        </Grid>
                        <Grid item xs={12} className="pt-8">
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                {contextObj('common', 'submit')}
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        </div>
    );
}

export default ResetPasswordPage;