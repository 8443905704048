import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { userService } from '../reducers/user';
import notifyReducer from '../reducers/notify';
import loadingReducer from '../reducers/loading';
import authUserReducer from '../reducers/authUser';
import { productService } from '../reducers/product';
import { galleryService } from '../reducers/gallery';
import { contactService } from '../reducers/contact';
import { categoryService } from '../reducers/category';
import { dashboardService } from '../reducers/dashboard';
import contactUnReadReducer from '../reducers/contactUnRead';

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        notify: notifyReducer,
        loading: loadingReducer,
        authUser: authUserReducer,
        contactUnRead: contactUnReadReducer,
        [userService.reducerPath]: userService.reducer,
        [categoryService.reducerPath]: categoryService.reducer,
        [productService.reducerPath]: productService.reducer,
        [galleryService.reducerPath]: galleryService.reducer,
        [contactService.reducerPath]: contactService.reducer,
        [dashboardService.reducerPath]: dashboardService.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([userService.middleware, categoryService.middleware, productService.middleware, galleryService.middleware, contactService.middleware, dashboardService.middleware]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)