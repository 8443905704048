import { createSlice } from '@reduxjs/toolkit';

const initialState = {value: []};

export const notifySlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {
        openSnackbar: (state, action) => {
            state.value = [{...action.payload, key: new Date().getTime()+Math.random()}];
        },
        removeSnackbar: (state, action) => {
            state.value = [];
        },
    },
})

export const { openSnackbar, removeSnackbar } = notifySlice.actions;
export default notifySlice.reducer;