import { CssBaseline } from '@mui/material';
import { Toolbar } from '@mui/material';

import Header from '../components/header';
import Footer from '../components/footer';
import Carousel from '../components/carousel';

const HomeLayout = ({children, i18n, contextObj, menuActiveKey}) => {
    return (
        <main style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#F7F4E9'}}>
            <CssBaseline />
            <Header i18n={i18n} contextObj={contextObj} menuActiveKey={menuActiveKey} />
            <Toolbar sx={{ display: { xs: 'block', md: 'none' } }} />
            <Carousel />
            {children}
            <Footer i18n={i18n} contextObj={contextObj} />
        </main>
    );
}

export default HomeLayout;