import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import { store } from './store/stroe';

// import i18n (needs to be bundled ;))
import './configs/ConfigLang';

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);