import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, Grid, Divider } from '@mui/material';

import DateObject from '../utils/DateObject';

const ModalContactInfo = ({open = false, handleClose = () => {}, handleConfirm = () => {}, selectedContact, contextObj}) => {
    const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={handleClose} aria-labelledby="modal-contact-title" aria-describedby="modal-contact-detail">
            <DialogTitle id="modal-contact-title" className='pb-1'>
                {contextObj('adminContact', 'contactInfo')}
            </DialogTitle>
            <DialogContent id="modal-contact-detail">
                <Grid container>
                    <Grid item xs={12} className='pb-2'>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className='py-1.5'>
                        <span>{contextObj('adminContact', 'contactName')}:</span>&nbsp;&nbsp;<strong>{selectedContact['full_name']}</strong>
                    </Grid>
                    <Grid item xs={12} className='py-1.5'>
                        <span>{contextObj('common', 'email')}:</span>&nbsp;&nbsp;<a className='font-bold' href={`mailto:${selectedContact['email']}`}>{selectedContact['email']}</a>
                    </Grid>
                    <Grid item xs={12} className='py-1.5'>
                        <span>{contextObj('adminContact', 'contactTel')}:</span>&nbsp;&nbsp;<a className='font-bold' href={`tel:${selectedContact['phone_number']}`}>{selectedContact['phone_number']}</a>
                    </Grid>
                    <Grid item xs={12} className='py-1.5'>
                        <span>{contextObj('adminContact', 'contactAt')}:</span>&nbsp;&nbsp;<strong>{DateObject.convertDateTime(selectedContact['created_at'], "DD/MM/yyyy HH:mm:ss")}</strong>
                    </Grid>
                    <Grid item xs={12} className='py-1.5'>
                        <span>{contextObj('home', 'message')}:</span>&nbsp;&nbsp;<strong>{selectedContact['message']}</strong>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleConfirm} color={'primary'}>
                    {contextObj('common', 'ok')}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ModalContactInfo;