const palette = {
    primary: {
        light: '#52d27d',
        main: '#00a050',
        dark: '#007025',
        contrastText: "#fff"
    },
    facebook: {
        main: '#1B74E4',
      contrastText: '#fff',
    }
    // secondary: {
    //     light: '#fbffff',
    //     main: '#c8d6dd',
    //     dark: '#97a5ab',
    //     contrastText: "#a5a5a5"
    // },
    // bgColor: {
    //     light: '#334153',
    //     main: '#0b1b2a',
    //     dark: '#000000',
    // }
};

export default palette;