import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from "./lang/en";
import th from "./lang/th";

const resources = {
    en: { translation: en },
    th: { translation: th }
};

const ConfigLang = i18n.use(initReactI18next).init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default ConfigLang;