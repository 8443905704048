import NoImage from '../assets/images/no-image.png';

const ImageWithOnError = (props) => {

    return (
        <img {...props} alt={props.alt} loading="lazy" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=NoImage; }} style={{background: `url(${NoImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} />
    )

}

export default ImageWithOnError;