import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Typography, Paper } from '@mui/material';
import { Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from "recharts";

import DateObject from '../utils/DateObject';
import { useGetDashboardQuery } from '../reducers/dashboard';

const AdminDashboardPage = ({contextObj, i18n}) => {
    const { data: dashboard } = useGetDashboardQuery();
    const authUser = useSelector((state) => state.authUser)?.value;
    const [randomKey, setRandomKey] = useState(Math.random());

    useEffect(()=>{
        window.addEventListener("resize", resizeWindow);
    }, [])
    
    const resizeWindow = () => {
        setRandomKey(Math.random())
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' className='font-bold'>{contextObj('adminMenu', 'dashboard')}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Paper className='p-4 md:p-6 text-center'>
                    <span className='font-bold text-2xl'>{`${authUser['displayName']} (${authUser['userEmail']})`}</span>
                    <br />
                    <br />
                    <span className='text-xl mt-4'>{`${contextObj('adminUser', 'lastLogin')}: ${DateObject.convertDateTime(authUser['lastLogin'], "DD/MM/yyyy HH:mm:ss", i18n?.language === 'en')}`}</span>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className='p-4'>
                    <Grid container>
                        <Grid item xs={12} style={{minHeight: (dashboard) ? dashboard['categoryList']?.length * 50 : 500}}>
                            <ResponsiveContainer width="100%" height="100%" key={randomKey}>
                                <BarChart width={500} height={300} data={dashboard && (dashboard['categoryList'] || [])} layout="vertical" margin={{ top: 0, right: 0, left: 40, bottom: 0 }}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis type="number"  />
                                    <YAxis type="category" dataKey={i18n?.language === 'en' ? "title_en" : "title_th"} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="view_count" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            
           
        </Grid>
    );
}

export default AdminDashboardPage;