import ApiProxy from '../utils/ApiProxy';

class LogoutService {

    static async logoutUser() {
        return new Promise(async (resolve, reject) => {
            await ApiProxy.sendRequest('GET', 'logout').catch((error) => {
                reject(error);
            });
            resolve();
        })
    }

}

export default LogoutService;