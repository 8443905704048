import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate, Link } from "react-router-dom";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Grid, Typography, Button, IconButton } from '@mui/material';

import { openSnackbar } from '../reducers/notify';
import Breadcrumb from '../components/breadcrumb';
import SearchTextField from '../components/search';
import EnhancedTable from '../components/enhanceTable';
import { useGetCategoryListQuery, useUpdateCategoryPopularMutation } from '../reducers/category';

const AdminCategoryManagementPage = ({contextObj, i18n}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: categoryList } = useGetCategoryListQuery();
    const [ quickSearchVal, setQuickSearchVal ] = useState('');
    const [ updateCategoryPopular ] = useUpdateCategoryPopularMutation();

    const breadcrumbList = [
        { title: contextObj('adminMenu', 'products'), link: "/admin-product" },
        { title: contextObj('adminMenu', 'category') }
    ];

    const headCells = [
        { id: 'gen_is_popular', sortKey: 'is_popular', align: "center", numeric: false, label: contextObj('adminCategory', 'popular'), clickDisabled: true, padding: 'checkbox' },
        { id: 'title_en', align: "left", numeric: false, label: contextObj('adminCommon', 'title')+' (EN)' },
        { id: 'title_th', align: "left", numeric: false, label: contextObj('adminCommon', 'title')+' (TH)' },
        { id: 'updated_at', align: "left", numeric: false, label: contextObj('adminCommon', 'lastUpdate'), isDate: true, dateFormat: 'DD/MM/yyyy HH:mm:ss' },
    ];

    const handleOnSearch = (val) => {
        setQuickSearchVal(val)
    }

    const handleOnRowClick = (category) => {
        navigate(`/admin-category/${category['category_key']}`);
    }

    const handleOnPuplar = (categoryKey, getPopularVal) => {
        updateCategoryPopular({categoryKey: categoryKey, initialPost: {isPopular: getPopularVal ? 1 : 0}}).then((res)=>{
            if(res.data?.statusCode === 200){
                dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
            }
        }).catch((err)=>{
            dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
        });
    }

    const genCategoryPopular = (getCategory) => {
        let genCategory = {...getCategory};
        const tempPopular = genCategory['is_popular'] ? true : false;
        genCategory['gen_is_popular'] = genCategory['is_popular'] ? (
            <IconButton onClick={() => handleOnPuplar(genCategory['category_key'], !tempPopular)} aria-label="category-popluar" className='text-amber-400 ml-0.5'>
                <StarIcon />
            </IconButton>
        ) : (
            <IconButton onClick={() => handleOnPuplar(genCategory['category_key'], !tempPopular)} aria-label="category-popluar" className='ml-0.5'>
                <StarBorderIcon />
            </IconButton>
        );
        
        return genCategory;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Breadcrumb navigateList={breadcrumbList} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h5' className='font-bold'>{contextObj('adminMenu', 'category')}</Typography>
            </Grid>
            <Grid item xs={5}>
                <SearchTextField searchVal={quickSearchVal} setSearchVal={handleOnSearch} contextObj={contextObj} />
            </Grid>
            <Grid item xs={7} className="text-end">
                <Button component={Link} to={'/admin-category/create'} variant="outlined" className={"border-2"} startIcon={<AddIcon fontSize="small"/>}>{contextObj('adminMenu', 'addCategory')}</Button>
            </Grid>
            <Grid item xs={12}>
                <EnhancedTable headCellList={headCells} tableDataList={categoryList?.filter(obj=>{return String(obj['title_th'].toLowerCase()).includes(quickSearchVal.toLowerCase()) || String(obj['title_en'].toLowerCase()).includes(quickSearchVal.toLowerCase())})?.map(category => genCategoryPopular(category))} isCheckBox={false} contextObj={contextObj} handleTableClick={handleOnRowClick} isTH={(i18n.language === 'th')} />
            </Grid>
        </Grid>
    );
}

export default AdminCategoryManagementPage;