import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import SearchTextField from '../components/search';
import EnhancedTable from '../components/enhanceTable';
import { useGetContactListQuery } from '../reducers/contact';
import ModalContactInfo from '../components/modalContactInfo';

const AdminContactManagementPage = ({contextObj, i18n}) => {
    const { data: contactList } = useGetContactListQuery();
    const [quickSearchVal, setQuickSearchVal] = useState('');
    const [openModalInfo, setOpenModalInfo] = useState(false);
    const [selectedContact, setSelectedContact] = useState({});

    const headCells = [
        { id: 'full_name', align: "left", numeric: false, label: contextObj('adminContact', 'contactName') },
        { id: 'phone_number', align: "left", numeric: false, label: contextObj('adminContact', 'contactTel') },
        { id: 'created_at', align: "left", numeric: false, label: contextObj('adminContact', 'contactAt'), isDate: true, dateFormat: 'DD/MM/yyyy HH:mm:ss' },
    ];

    const handleOnRowClick = (contactObj) => {
        setSelectedContact(contactObj);
        setOpenModalInfo(true);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h5' className='font-bold'>{contextObj('adminMenu', 'contacts')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <SearchTextField searchVal={quickSearchVal} setSearchVal={(val)=>setQuickSearchVal(val)} contextObj={contextObj} />
            </Grid>
            <Grid item xs={12}>
                <EnhancedTable headCellList={headCells} tableDataList={contactList?.filter(obj=>{return String(obj['full_name'].toLowerCase()).includes(quickSearchVal.toLowerCase()) || String(obj['phone_number'].toLowerCase()).includes(quickSearchVal.toLowerCase())})} isCheckBox={false} handleTableClick={handleOnRowClick} contextObj={contextObj} isTH={(i18n.language === 'th')} />
            </Grid>
            <ModalContactInfo open={openModalInfo} handleClose={()=>setOpenModalInfo(false)} handleConfirm={()=>setOpenModalInfo(false)} contextObj={contextObj} selectedContact={selectedContact} />
        </Grid>
    );
}

export default AdminContactManagementPage;