import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: { userID: "", lastLogin: "", userEmail: "", displayName: "" } };
export const authUserSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        setAuthUser: (state, action) => {
            state.value = {...action.payload};
        }
    },
})

export const { setAuthUser } = authUserSlice.actions;
export default authUserSlice.reducer;