import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: 0 };
export const contactUnReadSlice = createSlice({
    name: 'contactUnRead',
    initialState,
    reducers: {
        setContactUnRead: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const { setContactUnRead } = contactUnReadSlice.actions;
export default contactUnReadSlice.reducer;