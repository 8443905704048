import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from '@mui/material';

const ModalConfirm = ({open = false, handleClose = () => {}, handleConfirm = () => {}, title = '', description = '', isNegative = false, isDelete = false, contextObj}) => {
    const fullScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    return (
        <Dialog fullScreen={fullScreen} fullWidth open={open} onClose={handleClose} aria-labelledby="modal-confirmation-title" aria-describedby="modal-confirmation-detail">
            <DialogTitle id="modal-confirmation-title">
                {isNegative ? <ErrorIcon color='error' /> : isDelete ? <DeleteIcon color='error' /> : null} {title || contextObj('common', 'areYouSure')}
            </DialogTitle>
            <DialogContent id="modal-confirmation-detail">
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='inherit' onClick={handleClose}>
                    {contextObj('common', 'cancel')}
                </Button>
                <Button variant='contained' onClick={handleConfirm} color={isNegative || isDelete ? 'error' : 'primary'}>
                    {!isDelete ? contextObj('common', 'submit') : contextObj('common', 'delete')}
                </Button>
            </DialogActions>
        </Dialog>
    )

}

export default ModalConfirm;