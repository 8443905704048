const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL;

class ApiProxy {

    static async sendRequest(method, path, json=null) {
        if(!json){ json = {} }
        return new Promise(async (resolve, reject) => {
            const response = await axios({
                method: method,
                url: API_URL + path,
                data: json,
                withCredentials: true
            }).catch((error) => {
                reject(error);
            });

            if(response){
                resolve(response.data);
            }else{
                resolve();
            }
        });
    }
    
}

export default ApiProxy;