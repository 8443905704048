import React, { useEffect } from 'react';
import { Pagination } from "swiper";
import StarIcon from '@mui/icons-material/Star';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Container, Button, useMediaQuery } from '@mui/material';
import { faLine, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

import useStyles from '../assets/styles';
import Carousel from '../components/carousel';
import Catalog from '../assets/files/catalog.pdf';
import ContactForm from '../components/contactForm';
import HomeImage from '../assets/images/withframe.png';
import CatalogBG from '../assets/images/catalogBG.jpg';
import NoImage from '../assets/images/no-image-front.png';
import { useGetSiteCategoryPopularObjQuery } from '../reducers/category';

const API_URL = process.env.REACT_APP_API_URL;

const HomePage = ({contextObj, menuActiveKey, i18n}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { data: popularCategoryList } = useGetSiteCategoryPopularObjQuery();
    const isSMUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const isMobileUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const menus = [ {key: 'home', title: contextObj('menu',  'home'), link: '/'}, {key: 'product', title: contextObj('menu',  'product'), link: '/product'}, {key: 'gallery', title: contextObj('menu',  'gallery'), link: '/gallery'} ];

    const scaleHeader = () => {
        var scalable = document.querySelectorAll('.scale--js');
        var margin = 10;
        for (var i = 0; i < scalable.length; i++) {
            var scalableContainer = scalable[i].parentNode;
            scalable[i].style.transform = 'scale(1)';
            var scalableContainerWidth = scalableContainer.offsetWidth - margin;
            var scalableWidth = scalable[i].offsetWidth + 20;
            scalable[i].style.transform = 'scale(' + scalableContainerWidth / scalableWidth + ')';
            scalableContainer.style.height = scalable[i].getBoundingClientRect().height + 'px';
        }
    }
    
    useEffect(()=>{
        scaleHeader();
        window.addEventListener("resize", scaleHeader);
    }, []);
    
    const onNavigate = (categoryKey) => {
        navigate(`/product/${categoryKey}`);
    }

    return (
        <div className="flex justify-center pb-2">
            <Container maxWidth="xl" className='py-4'>
                <div className="scale__container--js">
                    <span className="scale--js" style={{color: '#2F4C39'}}>Vandaseeds</span>
                </div>
                <hr className='w-full mt-1 md:pt-0 mb-2 sm:mb-6 h-1 md:h-1.5' style={{background: '#2F4C39'}} />
                <Carousel />
                <Grid container>
                    <Grid item xs={12} md={7} lg={6} className="self-center py-6">
                        <span className='text-3xl sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl'>
                            From Vandaseeds to feed the whole world. 
                        </span>
                        <p className='pt-3'>
                            {contextObj('home', 'secQuote')}
                        </p>
                    </Grid>
                    <Grid item xs={12} md={5} lg={6} className="self-center pb-6 md:py-6">
                        <img alt="vandaseeds" src={HomeImage} className="w-full" />
                    </Grid>
                    {!isMobileUp && popularCategoryList && (popularCategoryList?.length > 0) ?
                        <Grid item xs={12} className='py-6 px-4'>
                            <Swiper className='-mx-4 -mt-4' loop={true} autoHeight={true} centeredSlides={true} pagination={{ clickable: true, renderBullet: function (index, className) { return '<span class="' + className + ' bg-green-800"></span>'; } }} modules={[Pagination]}>
                                {popularCategoryList?.map((categoryObj, imageIndex)=>(
                                    <SwiperSlide key={`popular-image-${imageIndex}`}>
                                        <div id='category-parent' className='bg-no-repeat bg-center bg-cover relative cursor-pointer rounded-xl'  onClick={()=>onNavigate(categoryObj['category_key'])} style={{minHeight: 300, backgroundColor: 'rgb(47, 76, 57)', backgroundImage: !categoryObj['thumbnail'] ? `url(${NoImage})` : `url(${API_URL}images/${categoryObj['thumbnail']})`}}>
                                            <div className='absolute bottom-0 p-4 pt-8 bg-gradient-to-t from-black to-transparent rounded-xl' style={{width: isSMUp ? `calc(100vw - 95px)` : `calc(100vw - 80px)`}}>
                                                <span className='font-bold text-xl' style={{color: 'rgba(255, 255, 255, 1)'}}>{i18n?.language === 'en' ? categoryObj['title_en'] : categoryObj['title_th']} {categoryObj['is_popular'] ? <StarIcon className='text-amber-400 ml-0.5' /> : null}</span>
                                                <p className='text-white category-description'>
                                                    {i18n?.language === 'en' ? categoryObj['description_en'] : categoryObj['description_th']}
                                                </p>
                                            </div>
                                        </div>    
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </Grid>
                    : popularCategoryList && (popularCategoryList?.length > 0) ?
                        popularCategoryList?.map((category)=>{
                            return(
                                <Grid key={'category-page-'+category['category_key']} item xs={12} sm={6} md={4} className='py-6 px-2'>
                                    <div id='category-parent' className='bg-no-repeat bg-center bg-cover relative cursor-pointer rounded-xl' onClick={()=>onNavigate(category['category_key'])} style={{minHeight: 300, backgroundColor: 'rgb(47, 76, 57)', backgroundImage: !category['thumbnail'] ? `url(${NoImage})` : `url(${API_URL}images/${category['thumbnail']})`}}>
                                        <div className='w-full absolute bottom-0 p-4 pt-8 bg-gradient-to-t from-black to-transparent rounded-xl'>
                                            <span className='font-bold text-xl' style={{color: 'rgba(255, 255, 255, 1)'}}>{i18n?.language === 'en' ? category['title_en'] : category['title_th']} {category['is_popular'] ? <StarIcon className='text-amber-400 ml-0.5' /> : null}</span>
                                            <p className='text-white category-description'>
                                                {i18n?.language === 'en' ? category['description_en'] : category['description_th']}
                                            </p>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                    : null}
                    <Grid item xs={12} className='text-center p-8 md:p-16 bg-center bg-cover' style={{backgroundImage: `url(${CatalogBG})`}}>
                        <span className='text-4xl md:text-5xl text-white'>
                            {contextObj('home', 'ourCatalog')}
                        </span>
                        <hr className='w-40 md:w-48 mx-auto mt-2' />
                        <p className='text-white py-4 md:py-6 max-w-md mx-auto'>
                            {contextObj('home', 'catalogOuote')}
                        </p>
                        <Button component={'a'} variant="contained" size="large" className='text-lg md:text-xl rounded-none shadow-none mt-6 md:mt-8 py-4 px-6 md:py-6 md:px-8' href={Catalog} download="vandaseeds-catalog">
                            {contextObj('home', 'downloadNow')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6} className='text-center py-8'>
                        <span className='text-4xl'>
                            {contextObj('home', 'contactUs')}
                        </span>
                        <hr className='w-40 mx-auto h-1' style={{background: '#353433'}} />
                        <ContactForm contextObj={contextObj} />
                    </Grid>
                    <Grid item xs={12} md={6} className="md:pl-4 md:pt-4">
                        <iframe title="vandaseeds-maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3779.7751215814556!2d98.90930941590887!3d18.67408456933265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30da3132025f7ea9%3A0xff7d627458345793!2z4Lia4Lij4Li04Lip4Lix4LiX4LmB4Lin4LiZ4LiU4LmJ4Liy4LiL4Li14Liq4Liq4LmMIOC4iOC4s-C4geC4seC4lA!5e0!3m2!1sen!2sth!4v1661014224274!5m2!1sen!2sth" width="100%" height="100%" style={{border: 0, minHeight: 300}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </Grid>
                    <Grid item xs={12} className="py-6">
                        <hr className='h-0.5' style={{background: '#353433'}} />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <span className={`${classes.mainColor} text-lg sm:text-xl font-bold`}>VANDA SEEDS CO.,LTD</span>
                        <br />
                        <span className='text-md sm:text-lg'>Professional vegetable seeds breeding and production company</span>
                        <p className='pt-4 pb-2'>{contextObj('common', 'address')}</p>
                        <span>{contextObj('common', 'tel')}: <a href="tel:+6653-442-357">05-344-2357</a></span>
                        <br />
                        <span>{contextObj('common', 'fax')}: 05-344-2357</span>
                        <br />
                        <div>{contextObj('common', 'mobile')}: <a href="tel:+6693-254-5665">093-254-5665</a>, <a href="tel:+6662-864-9992">062-864-9992 ({contextObj('home', 'saleTeam')})</a></div>
                        <a href="mailto:vandaseeds@gmail.com">{contextObj('common', 'email')}: vandaseeds@gmail.com</a>
                    </Grid>
                    <Grid item xs={12} md={3} className='hidden md:block px-4'>
                        <span className='text-2xl font-bold'>
                            {contextObj('home', 'siteLink')}
                        </span>
                        <div className='py-1'></div>
                        {menus?.map(menu=>{
                            return (
                                <div key={'footer-menu-'+menu['key']} className='py-0.5'>
                                    <Link to={menu['link']} className={(menuActiveKey === menu['key']) ? classes.mainColor : ''}>{menu['title']}</Link>
                                    <br/>
                                </div>
                            )
                        })}
                    </Grid>
                    <Grid item xs={12} md={4} className='pt-4 md:pt-0'>
                        <span className='text-2xl font-bold'>
                            {contextObj('home', 'followUs')}
                        </span>
                        <div className='py-1'></div>
                        <p className='py-0.5'>{contextObj('home', 'followUsQuote')}</p>
                        <a href='https://www.facebook.com/V.vandaseed' target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faSquareFacebook} size="3x" fixedWidth className='cursor-pointer' style={{color: '#1B74E4'}} />
                        </a>
                        {/* <a href='https://www.facebook.com/V.vandaseed' target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLine} size="3x" fixedWidth className='cursor-pointer' style={{color: '#06c755'}} />
                        </a> */}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default HomePage;