import React from 'react';

const Footer = () => {
    return(
        <footer className='w-screen text-white text-center text-xs p-4 mt-auto' style={{backgroundColor: '#333', zIndex: 1200}}>
            &copy; {new Date().getFullYear()} VANDA SEEDS CO., LTD. All rights reserved. <a href='https://www.pepatcha.com' className='underline hidden sm:inline-block'>Powered by PePatcha™</a>
        </footer>
    )
}

export default Footer;