import React, { useEffect } from 'react';
import { Divider, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';

import useStyles from '../assets/styles';
import HomeLoading from '../components/homeLoading';
import NoImage from '../assets/images/no-image-front.png';
import { useGetCategoryListQuery } from '../reducers/category';

const API_URL = process.env.REACT_APP_API_URL;

const ProducPage = ({contextObj, i18n}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { data: categoryList, isLoading: catagoryListLoading, isError: catagoryListError } = useGetCategoryListQuery();

    useEffect(()=>{
        if(catagoryListError){
            navigate('/');
        }
    }, [catagoryListError, navigate]);

    const onNavigate = (categoryKey) => {
        navigate(`/product/${categoryKey}`);
    }

    return (
        catagoryListLoading ? <HomeLoading /> :
        categoryList ?
            <div style={{flex: 1}}>
                <div className="container mx-auto py-4">
                    <div className='p-4'>
                        <span className={`${classes.mainTextColor} font-bold text-3xl`}>{contextObj('menu', 'product')}</span>
                        <Divider className='pt-1' />
                        <Grid container spacing={2} className='pt-4'>
                            {categoryList?.map((category)=>{
                                return(
                                    <Grid key={'category-page-'+category['category_key']} item xs={12} sm={6} md={4}>
                                        <div id='category-parent' className='bg-no-repeat bg-center bg-cover relative cursor-pointer rounded-xl' onClick={()=>onNavigate(category['category_key'])} style={{minHeight: 300, backgroundColor: 'rgb(47, 76, 57)', backgroundImage: !category['thumbnail'] ? `url(${NoImage})` : `url(${API_URL}images/${category['thumbnail']})`}}>
                                            <div className='w-full absolute bottom-0 p-4 pt-8 bg-gradient-to-t from-black to-transparent rounded-xl'>
                                                <span className='font-bold text-xl' style={{color: 'rgba(255, 255, 255, 1)'}}>{i18n?.language === 'en' ? category['title_en'] : category['title_th']} {category['is_popular'] ? <StarIcon className='text-amber-400 ml-0.5' /> : null}</span>
                                                <p className='text-white category-description'>
                                                    {i18n?.language === 'en' ? category['description_en'] : category['description_th']}
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </div>
                </div>
            </div>
        : null
    );
}

export default ProducPage;