import { makeStyles } from '@mui/styles';

const important = (value) => {
    return (value + ' !important');
}

const useStyles = makeStyles(theme => ({
    mainColor: {
        color: important(theme.palette.primary.main)
    },
    mainBGColor: {
        backgroundColor: important(theme.palette.primary.main)
    },
    mainTextColor: {
        color: important('#353433')
    },
    drawerActive: {
        fontWeight: important('bold'),
        marginLeft: important('8px'),
        marginRight: important('8px'),
        borderRadius: important('12px'),
        color: important('#fff'),
        backgroundColor: important(theme.palette.primary.dark),
    },
    drawerInActive: {
        fontWeight: important('bold'),
        marginLeft: important('8px'),
        marginRight: important('8px'),
        borderRadius: important('12px'),
        color: important('#444'),
        '&:hover': {
            color: important('white'),
            backgroundColor: important('rgb(68, 179, 105)')
        }
    },
    menuActive: {
        fontWeight: important('bold'),
        margin: important("16px 8px"),
        padding: important("6px 16px"),
        color: important('white'),
        backgroundColor: important(theme.palette.primary.dark),
        borderRadius: important('8px'),
        boxShadow: important('3px 3px 6px -4px rgba(0,0,0,0.59); -webkit-box-shadow: 3px 3px 6px -4px rgba(0,0,0,0.59); -moz-box-shadow: 3px 3px 6px -4px rgba(0,0,0,0.59);')
    },
    menuInActive: {
        fontWeight: important('bold'),
        margin: important("16px 8px"),
        padding: important("6px 16px"),
        borderRadius: important('8px'),
        color: important('white'),
        '&:hover': {
            backgroundColor: important('rgb(68, 179, 105)'),
        }
    },
    menuInActiveHome: {
        fontWeight: important('bold'),
        margin: important("16px 8px"),
        padding: important("6px 16px"),
        borderRadius: important('8px'),
        color: important('#353433'),
        '&:hover': {
            color: important('white'),
            backgroundColor: important('rgb(68, 179, 105)'),
        }
    },
    customTableCellBorder: {
        borderRight: important('2px solid #f9f9f9'),
        borderBottom: important('2px solid #f9f9f9')
    },
    customTableCellBorderLast: {
        borderBottom: important('2px solid #f9f9f9')
    },
}));

export default useStyles;