import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, Typography, Paper, useMediaQuery, TextField, Button, Divider, InputAdornment, IconButton } from '@mui/material';

import Validator from "../utils/Validator";
import { openSnackbar } from '../reducers/notify';
import Breadcrumb from '../components/breadcrumb';
import { useGetUserObjQuery, useCreateUserMutation, useUpdateUserMutation } from '../reducers/user';

const AdminUserDetailManagementPage = ({contextObj}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userID } = useParams();
    const isCreateMode = (userID === 'create');
    const [ createUser ] = useCreateUserMutation();
    const [ updateUser ] = useUpdateUserMutation();
    const isSMUp = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const { data: userObj, isError: userObjError, isLoading: userObjLoading, } = useGetUserObjQuery(userID, { skip: isCreateMode });

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [displayName, setDisplayName] = useState('');
    const [inValidEmail, setInValidEmail] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [inValidPassword, setInValidPassword] = useState(false);
    
    const breadcrumbList = [
        { title: contextObj('adminMenu', 'users'), link: "/admin-user" },
        { title: isCreateMode ? contextObj('adminUser', 'createUser') : userObj ? userObj['display_name'] : '-' },
    ];

    useEffect(()=>{
        if(userObjError && !isCreateMode){
            navigate('/admin-user');
        }
    }, [userObjError, isCreateMode, navigate]);

    useEffect(()=>{
        if(!isCreateMode && userObj){
            setDisplayName(userObj['display_name']);
        }
    }, [isCreateMode, userObj]);

    const onPasswordChange = (value) => {
        setPassword(value);
        setInValidPassword(false);
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setIsSubmit(true);

        if(isCreateMode && (!email || !displayName || !password || !confirmPassword)){
            return;
        }else if(!isCreateMode && !displayName){
            return;
        }else if((password || confirmPassword) && (password !== confirmPassword)){
            setConfirmPassword("");
            return;
        }else if(isCreateMode && !Validator.validateData(email, 'email').isValid){
            setInValidEmail(true);
            return;
        }else if(password && !Validator.validateData(password, 'password').isValid){
            setInValidPassword(true);
            return;
        }

        if(isCreateMode){
            createUser({ email, password, displayName }).then((res)=>{
                if(res.data?.statusCode === 201){
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                    setPassword("");
                    setConfirmPassword("");
                    navigate('/admin-user');
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }else{
            const genData = { displayName, password };
            updateUser({userID: userID, initialPost: genData}).then((res)=>{
                if(res.data?.statusCode === 200){
                    dispatch(openSnackbar({ options: { variant: "success" }, message: "success" }));
                    setPassword("");
                    setConfirmPassword("");
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: res.data?.message || res.error?.data?.message || contextObj('common', 'somethingWrong') }));
                }
            }).catch((err)=>{
                dispatch(openSnackbar({ options: { variant: "error" }, message: contextObj('common', 'somethingWrong') }));
            });
        }
    }

    return (
        userObjLoading ? <></> :
        userObj || isCreateMode ?
        <form autoComplete="off" onSubmit={handleOnSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Breadcrumb navigateList={breadcrumbList} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h5' className='font-bold'>
                        {isCreateMode ? contextObj('adminUser', 'createUser') : userObj['display_name']}
                    </Typography>
                </Grid>
                <Grid item xs={12} className='pt-2 sm:pt-4'>
                    <Grid container component={isSMUp ? Paper : null} className="sm:p-6 sm:pt-5">
                        <Grid item xs={12} md={6}  className='md:pr-2'>
                            <TextField label={contextObj('common', 'email')} defaultValue={isCreateMode ? email : userObj['email']} onChange={e=>isCreateMode ? setEmail(e.target.value) : ()=>{}} error={(isCreateMode && isSubmit && !email) || inValidEmail} helperText={((isCreateMode && isSubmit && !email) || inValidEmail) && `${contextObj('adminUser', 'emailInvalid')}`} size="small" margin="normal" fullWidth required autoComplete="off" disabled={!isCreateMode} />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pl-2'>
                            <TextField label={contextObj('adminUser', 'displayName')} id="user-display-name" value={displayName} onChange={e=>setDisplayName(e.target.value)} size="small" margin="normal" fullWidth autoComplete="off" error={isSubmit && !displayName} helperText={isSubmit && !displayName ? contextObj('adminUser', 'invalidDisplayName') : ""} />
                        </Grid>
                        {!isCreateMode &&
                            <>
                                <Grid item xs={12} className='pt-2'>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} className='mt-4'>
                                    <span className='font-bold'>
                                        {contextObj('adminUser', 'changePassword')}
                                    </span>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} md={6} className='md:pr-2'>
                            <TextField label={contextObj('common', 'password')} size="small" margin="normal" autoComplete="off" fullWidth value={password} onChange={(e)=>onPasswordChange(e.target.value)} type={isShowPassword ? "text" : "password"} error={(!password && isSubmit && isCreateMode) || inValidPassword} helperText={inValidPassword ? contextObj('adminUser', 'passwordInvalid') : (!password && isSubmit && isCreateMode) ? contextObj('adminUser', 'passwordInvalid2') : ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={()=>setIsShowPassword(!isShowPassword)} onMouseDown={e=>e.preventDefault()} edge="end">
                                                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} className='md:pl-2'>
                            <TextField label={contextObj('adminUser', 'confirmPassword')} size="small" margin="normal" autoComplete="off" fullWidth value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} type={isShowPassword ? "text" : "password"} error={(!confirmPassword && isSubmit && isCreateMode) || (password !== confirmPassword)} helperText={(!confirmPassword && isSubmit && isCreateMode) ? contextObj('adminUser', 'confirmPasswordInvalid') : (password !== confirmPassword) ? contextObj('adminUser', 'passwordInvalid3') : ""} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className='text-end pt-6'>
                    <Button type="submit" variant="contained" color="primary">
                        {contextObj('common', 'submit')}
                    </Button>
                </Grid>
            </Grid>
        </form>
        : null
    );
}

export default AdminUserDetailManagementPage;