import { Fragment, useState, useRef, cloneElement } from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CollectionsIcon from '@mui/icons-material/Collections';
import { Box, SwipeableDrawer, List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, IconButton, Container, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, Zoom, useScrollTrigger, Avatar, Tooltip, MenuItem } from '@mui/material';

import useStyles from '../assets/styles';
import Logo from '../assets/images/vanda-logo.png';
import FlagTH from '../assets/images/flag-th-32.png';
import FlagEN from '../assets/images/flag-en-32.png';
import { useGetCategoryListQuery } from '../reducers/category';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    boxShadow: 'unset',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], { easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen }),
}));

const ElevationScroll = (props) => {
    const { children, menuActiveKey, trigger } = props;

    return cloneElement(children, {
        position: trigger ? "fixed" : "absolute",
        sx: { backgroundColor: { xs: '#00a050', md: trigger ? "#00a050" : "transparent" }, color: 'white', backgroundImage: { md: !trigger && (menuActiveKey !== 'home') ? 'linear-gradient(rgba(0,0,0,0.5), transparent)' : 'unset' }, boxShadow: trigger ? '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)' : 'unset' },
    });
}
  
ElevationScroll.propTypes = { children: PropTypes.element.isRequired, window: PropTypes.func };

const Header = ({i18n, contextObj, menuActiveKey, widow}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { data: categoryList } = useGetCategoryListQuery();
    const menus = [ {key: 'home', title: contextObj('menu',  'home'), link: '/', icon: <HomeIcon />}, {key: 'product', title: contextObj('menu',  'product'), link: '/product', icon: <LocalOfferIcon />}, {key: 'gallery', title: contextObj('menu',  'gallery'), link: '/gallery', icon: <CollectionsIcon />} ];
    const productMenuRef = useRef(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [productOpen, setProductOpen] = useState(false);
    const trigger = useScrollTrigger({ threshold: 0, disableHysteresis: true, target: undefined });

    const toggleDrawer = (open) => (event) => {
        if(event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
    };

    const handleOpenProduct = () => {
        if(!productOpen){
          setProductOpen(true);
        }
    };
    
    const handleCloseProduct = () => {
        if(productOpen){
          setProductOpen(false);
        }
    };

    const handleCategoryNavigate = (categoryKey) => {
        handleCloseProduct();
        navigate(`/product/${categoryKey}`);
    }

    const handleChangeLang = () => {
        if(i18n.language === 'en'){
            i18n.changeLanguage('th')
            localStorage.setItem('lang', 'th');
        }else{
            i18n.changeLanguage('en')
            localStorage.setItem('lang', 'en');
        }
    }

    const genMainTitleNav = (getXS, getMD, getTitleVarian, getFlexGrow) => {
        return(
            <Fragment key={getXS+getMD+getTitleVarian}>
                <IconButton component={Link} to={'/'} sx={{ display: { xs: getXS, md: getMD }, mr: (getXS === 'flex') ? 2 : 3, p: 0 }}>
                    <Avatar alt="Switch Language" src={Logo} children='V' sx={{ width: 38, height: 38, backgroundColor: '#085c25', objectFit: 'cover' }} />
                </IconButton>
                {getXS === 'flex' &&
                    <Typography variant={getTitleVarian} noWrap component={Link} to="/" sx={{ mr: 2, display: { xs: getXS, md: getMD }, flexGrow: getFlexGrow, fontFamily: "monospace !important", fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none' }}>
                        VANDASEEDS
                    </Typography>
                }
            </Fragment>
        )
    }

    return(
        <>
            <ElevationScroll window={widow} menuActiveKey={menuActiveKey} trigger={trigger}>
                <AppBar>
                    <Container maxWidth="lg">
                        <Toolbar disableGutters>
                            {genMainTitleNav('none', 'flex', 'h6', 'unset')}
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton size="large" aria-haspopup="true" onClick={toggleDrawer(state=>!state)} color="inherit" edge="start">
                                    <Box sx={{ display: 'flex' }}>
                                        <Zoom in={isDrawerOpen} className={isDrawerOpen ? 'block' : 'hidden'}><CloseIcon/></Zoom>
                                        <Zoom in={!isDrawerOpen} className={!isDrawerOpen ? 'block' : 'hidden'}><MenuIcon/></Zoom>
                                    </Box>
                                </IconButton>
                            </Box>
                            {genMainTitleNav('flex', 'none', 'h5', 1)}
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                {menus.map((menu) => (
                                    <Fragment key={menu['key']}>
                                        <Button component={Link} to={menu['link']} ref={(menu['key'] === 'product') ? productMenuRef : null} aria-controls={productOpen ? 'product-menu' : undefined} aria-expanded={productOpen ? 'true' : undefined} aria-haspopup="true" onMouseOver={(menu['key'] === 'product') ? handleOpenProduct : handleCloseProduct} className={(menuActiveKey === 'home') && (menu['key'] !== menuActiveKey) && !trigger ? classes.menuInActiveHome : (menu['key'] === menuActiveKey) ? classes.menuActive : classes.menuInActive}>
                                            {menu['title']}
                                        </Button>
                                        {(menu['key'] === 'product') &&
                                            <Popper open={productOpen} anchorEl={productMenuRef.current} transition disablePortal>
                                                {({ TransitionProps }) => (categoryList && categoryList?.length) && (
                                                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleCloseProduct}>
                                                                <MenuList autoFocusItem={productOpen} id="product-menu" aria-labelledby="composition-button" onMouseLeave={handleCloseProduct}  >
                                                                    {categoryList?.map(category=>(
                                                                        <MenuItem key={`category-menu-${category['category_key']}`} onClick={()=>handleCategoryNavigate(category['category_key'])}>{(i18n.language === 'en') ? category['title_en'] : category['title_th']}</MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        }
                                    </Fragment>
                                ))}
                            </Box>
                            <Tooltip title="Change Language">
                                <IconButton onClick={handleChangeLang} sx={{ p: 0 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Grow in={(i18n.language === 'th')}>
                                            <img alt="th lang" src={FlagTH} className={(i18n.language === 'th') ? 'block rounded-lg' : "hidden"} style={{width: 26, height: 26}} loading="lazy"/>
                                        </Grow>
                                        <Grow in={(i18n.language === 'en')}>
                                            <img alt="en lang" src={FlagEN} className={(i18n.language === 'en') ? 'block rounded-lg' : "hidden"} style={{width: 26, height: 26}} loading="lazy"/>
                                        </Grow>
                                    </Box>
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
            <SwipeableDrawer disableBackdropTransition={true} anchor={'left'} open={isDrawerOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                <Toolbar />
                <Box sx={{ width: 240 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <List>
                        {menus.map((menu) => (
                            <Fragment key={`drawer-${menu['key']}`}>
                                <ListItem disablePadding component={Link} to={menu['link']}>
                                    <ListItemButton className={(menu['key'] === menuActiveKey) ? classes.drawerActive : classes.drawerInActive}>
                                        <ListItemIcon sx={{color: 'inherit'}}>
                                            {menu['icon']}
                                        </ListItemIcon>
                                        <ListItemText primary={menu['title']?.toUpperCase()} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider className='my-2' />
                            </Fragment>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>
        </>
    )
}

export default Header;